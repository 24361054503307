import {Container, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {friendlyDate} from "../../utils/DateUtils";
import {useKeycloak} from "@react-keycloak/web";
import {mapInstitute} from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useDownloaderThunks} from "../../store/thunks/DownloaderThunks";

const ForceCheck = () => {
    const [date, setDate] = useState(dayjs(new Date()))
    const [selectedInstitute, setSelectedInstitute] = useState('PT')

    const {keycloak} = useKeycloak()
    const {isLoading,} = useIsLoadingActions()


    const intl = useIntl()
    const messages = {
        institute: intl.formatMessage({id: "model.institute"}),
        forceDownload: intl.formatMessage({id: "forceScheduler.label.forceDownload"}),
        forceIncidents: intl.formatMessage({id: "forceScheduler.label.forceIncidents"}),
        deleteRegisters: intl.formatMessage({id: "forceScheduler.label.deleteRegisters"}),
    }

    const isWeekend = (date: dayjs.Dayjs) => {
        return date.day() === 0 || date.day() === 6
    }

    const renderWeekPickerDay = (date: dayjs.Dayjs, selectedDates: Dayjs[], pickersDayProps: PickersDayProps<Dayjs>): JSX.Element => {


        if (isWeekend(date)) {
            return <React.Fragment key={date.toISOString()}/>
        } else if (date.isBefore(dayjs())) {
            return <PickersDay
                key={date.toISOString()}
                sx={{bgcolor: 'red'}}
                {...pickersDayProps} />
        } else {
            return <PickersDay key={date.toISOString()} {...pickersDayProps} />
        }
    };

    const {
        downloadAndSaveIncidents,
        downloadAndSaveRegisters,
        shutdownDownloader,
        deleteRegisters,
        forceINPIUpdate
    } = useDownloaderThunks()

    const handleForceIncidentsDownload = async () => {
        downloadAndSaveIncidents(friendlyDate(date), selectedInstitute, "Scheduler successfully executed", "")
    }
    const handleForceDownload = async () => {
        downloadAndSaveRegisters(friendlyDate(date), selectedInstitute,
            "Scheduler successfully executed",
            "Scheduler could not be executed"
        )
    }
    const handleDeleteRegisters = () => {
        deleteRegisters(friendlyDate(date), selectedInstitute,
            "Registers successfully removed",
            "Registers could not be removed")
    }
    const handleShutdownDownloader = async () => {
        shutdownDownloader("Downloader shutdown", "Error on shutdown Downloader")
    }
    const handleINPIUpdater = async () => {
        forceINPIUpdate("INPI Registers Successfully Updated", "Error on Updating INPI Registers")
    }

    return (
        <>
            <Container className={"mt-2"}>
                <MySelect onChange={(evt) => setSelectedInstitute(evt.target.value)} labelId={"institute-label-id"}
                          label={messages.institute} value={selectedInstitute} className={"mb-2"}
                          selectId={"institute-dropdown-id"} data={
                    keycloak.tokenParsed?.institutes?.map((inst: string) => {
                        const institute = mapInstitute(inst)
                        if (institute) {
                            return <option key={institute.origin} value={institute.origin}>{institute.name}</option>
                        }
                    })
                }/>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker displayStaticWrapperAs="desktop"
                                      onChange={(date) => setDate(date ?? dayjs())} value={date}
                                      renderDay={renderWeekPickerDay}
                                      renderInput={(params: any) => {
                                          return <TextField
                                              {...params} />
                                      }}/>
                </LocalizationProvider>

                <div className={"mt-3"}>
                    <MyButton disabled={isLoading} label={messages.forceDownload} onClick={handleForceDownload}/>
                </div>
                <div className={"mt-3"}>
                    <MyButton disabled={isLoading} label={messages.forceIncidents}
                              onClick={handleForceIncidentsDownload}/>
                </div>
                <div className={"mt-3"}>
                    <MyButton disabled={isLoading} label={messages.deleteRegisters} onClick={handleDeleteRegisters}/>
                </div>
                <div className={"mt-3"}>
                    <MyButton disabled={isLoading} label={"Shutdown Downloader"} onClick={handleShutdownDownloader}/>
                </div>
                <div className={"mt-3"}>
                    <MyButton disabled={isLoading} label={"INPI Updater"} onClick={handleINPIUpdater}/>
                </div>
                {isLoading && <Spinner animation={"border"}/>}
            </Container>
        </>
    )
}

export default ForceCheck