import React from "react";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";

const TitularStatisticsTable = () => {


    const {titularCount} = useBulletinsActions()
    const titular = titularCount.content

    if (!titular) return <></>

    const intl = useIntl()
    const messages = {
        search: intl.formatMessage({id: "generics.label.search"}),
        name: intl.formatMessage({id: "model.name"}),
        quantity: intl.formatMessage({id: "bulletins.label.quantity"}),
        origin: "Origin"
    }

    return <>
        {
            titular.length > 0 &&
            <Table>
                <thead>
                <tr>
                    <th>{messages.name}</th>
                    <th>{messages.quantity}</th>
                    <th>{messages.origin}</th>
                </tr>
                </thead>
                <tbody>
                {
                    titular.map(({titularName, count, origin}) => {
                        return <tr key={titularName}>
                            <td>{titularName}</td>
                            <td>{count}</td>
                            <td>{origin}</td>
                        </tr>
                    })
                }
                </tbody>

            </Table>
        }
    </>

}

export default TitularStatisticsTable