import {requests} from "./API";


const basePath = "/downloader"
const DownloadRequests = {
    downloadAndSaveRegisters: (date: string, institute: string) => requests.get<boolean>(`${basePath}/${date}/${institute}`),
    downloadAndSaveIncidents: (date: string, institute: string) => requests.get<boolean>(`${basePath}/incidents/${date}/${institute}`),
    forceINPIUpdate: () => requests.get<boolean>(`${basePath}/update`),
}

export default DownloadRequests;