import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Customer} from "../../models/Customer";
import {BrandRegister} from "../../models/BrandRegister";
import {useAppDispatch, useAppSelector} from "../hooks";
export interface CustomerState {
    customers: Customer[],
    selectedCustomer: Customer,
    showUpdateModal: boolean,
    showRegisterModal: boolean,
    showObservingClassModal: boolean,
    showIgnoredWordsModal: boolean,
    showDetailsModal: boolean,
    showNewCustomerModal:boolean,
    selectedRegister:BrandRegister,
}

const customerState: CustomerState = {
    customers: [],
    selectedCustomer: {} as Customer,
    showUpdateModal: false,
    showRegisterModal: false,
    showObservingClassModal: false,
    showIgnoredWordsModal: false,
    showDetailsModal: false,
    showNewCustomerModal:false,
    selectedRegister:{} as BrandRegister,
}
export const defaultCustomer = (): Customer => {
    const customer = {} as Customer
    customer.language = "PT"
    return customer
}

export const defaultRegister = (): BrandRegister => {
    const customer = {} as BrandRegister
    customer.source = "PT"
    customer.registerClasses = []
    return customer
}


const customerSlice = createSlice({
    name: "customer",
    initialState: customerState,
    reducers: {
        clearState: () => customerState,
        addCustomer: (state, action: PayloadAction<Customer>) => {
            state.customers.push(action.payload)
        },
        setCustomers: (state, action: PayloadAction<Customer[]>) => {
            state.customers = action.payload
        },
        setSelectedCustomer: (state, action: PayloadAction<Customer>) => {
            state.selectedCustomer = action.payload
        },
        updateRegister: (state, action: PayloadAction<BrandRegister>) => {
            const register = action.payload
            const customerIndex = state.customers.findIndex(c => c?.brandRegisters?.find(br => br.id === register.id))

            if (customerIndex >= 0) {
                const existingCustomer = state.customers[customerIndex]

                const registerIndex = existingCustomer?.brandRegisters?.findIndex(br => br.id === register.id)
                if (registerIndex && existingCustomer && existingCustomer.brandRegisters) {
                    existingCustomer.brandRegisters[registerIndex] = register
                    state.customers[customerIndex] = existingCustomer
                }
            }
        },
        updateCustomer: (state, action: PayloadAction<Customer>) => {

            const updatedCustomer = action.payload
            const existingCustomerId = state.customers.findIndex(c => c.id === updatedCustomer.id)
            if (existingCustomerId >= 0) {
                state.customers[existingCustomerId] = updatedCustomer
            }
        },
        changeUpdateModal: (state, action: PayloadAction<boolean>) => {
            state.showUpdateModal = action.payload
        },
        changeRegisterModal: (state, action: PayloadAction<boolean>) => {
            state.showRegisterModal = action.payload
        },
        changeObservingClassModal: (state, action: PayloadAction<boolean>) => {
            state.showObservingClassModal = action.payload
        },
        changeIgnoredWordsModal: (state, action: PayloadAction<boolean>) => {
            state.showIgnoredWordsModal = action.payload
        },
        changeDetailsModal: (state, action: PayloadAction<boolean>) => {
            state.showDetailsModal = action.payload
        },
        setShowNewCustomerModal:(state,action:PayloadAction<boolean>)=>{
            state.showNewCustomerModal=action.payload
        },
        setSelectedRegister:(state, action:PayloadAction<BrandRegister>)=>{
            state.selectedRegister=action.payload
        },
    }
})

export default customerSlice.reducer

export const useCustomerActions = () => {
    const {
        addCustomer,
        updateRegister,
        changeObservingClassModal,
        changeIgnoredWordsModal,
        setCustomers,
        changeDetailsModal,
        changeRegisterModal,
        updateCustomer,
        setSelectedCustomer,
        changeUpdateModal,
        clearState,
        setShowNewCustomerModal,
        setSelectedRegister,
    } = customerSlice.actions
    const {
        customers,
        selectedCustomer,
        showUpdateModal,
        showIgnoredWordsModal,
        showObservingClassModal,
        showRegisterModal,
        showDetailsModal,
        showNewCustomerModal,
        selectedRegister,
    } = useAppSelector(state => state.customer)
    const dispatch = useAppDispatch()

    return {
        addCustomer: (customer: Customer) => {
            dispatch(addCustomer(customer))
        },
        updateRegister: (register: BrandRegister) => {
            dispatch(updateRegister(register))
        },
        changeObservingClassModal: (show: boolean) => {
            dispatch(changeObservingClassModal(show))
        },
        changeIgnoredWordsModal: (show: boolean) => {
            dispatch(changeIgnoredWordsModal(show))
        },
        setCustomers: (customers: Customer[]) => {
            dispatch(setCustomers(customers))
        },
        changeDetailsModal: (show: boolean) => {
            dispatch(changeDetailsModal(show))
        },
        changeRegisterModal: (show: boolean) => {
            dispatch(changeRegisterModal(show))
        },
        updateCustomer: (customer: Customer) => {
            dispatch(updateCustomer(customer))
        },
        setSelectedCustomer: (customer: Customer) => {
            dispatch(setSelectedCustomer(customer))
        },
        changeUpdateModal: (show: boolean) => {
            dispatch(changeUpdateModal(show))
        },
        clearState: () => {
            dispatch(clearState())
        },
        setShowNewCustomerModal:(showNewCustomerModal:boolean)=>{
            dispatch(setShowNewCustomerModal(showNewCustomerModal))
        },
        setSelectedRegister:(selectedRegister:BrandRegister)=>{
            dispatch(setSelectedRegister(selectedRegister))
        },

        customers,
        selectedCustomer,
        showUpdateModal,
        showIgnoredWordsModal,
        showObservingClassModal,
        showRegisterModal,
        showDetailsModal,
        showNewCustomerModal,
        selectedRegister,
    }

}