import React from "react";
import {Container} from "react-bootstrap";
import {TitularCount} from "../../models/TitularCount";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {Page} from "../../models/Page";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import TitularStatisticsTable from "./TitularStatisticsTable";
import {useBulletinsThunks} from "../../store/thunks/BulletinsThunks";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";

const TitularStatistics = () => {
    const {getTitularWithoutRepresentatives} = useBulletinsThunks()
    const {titularCount, setTitularCount, currentPage, setCurrentPage} = useBulletinsActions()
    const {isLoading, setIsLoading} = useIsLoadingActions()


    const intl = useIntl()
    const messages = {
        search: intl.formatMessage({id: "generics.label.search"}),
    }

    const handleRequestData = async () => {
        setTitularCount({} as Page<TitularCount[]>)
        setIsLoading(true)
        setCurrentPage(0)
        await requestPage(0)
        setIsLoading(false)
    }

    const requestPage = async (page: number) => {
        const result = await getTitularWithoutRepresentatives(page)
        if (result) {
            setTitularCount(result)
        }
    }


    return <>

        <Container className={"mt-3"}>
            <WrapperPageableTable
                searchButton={<MyButton disabled={isLoading} label={messages.search} onClick={handleRequestData}/>}
                table={<TitularStatisticsTable />} page={titularCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage} requestPage={requestPage}/>
        </Container>

    </>
}

export default TitularStatistics