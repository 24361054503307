import {useAppDispatch} from "../hooks";
import BulletinsRequests from "../../api/bulletins/BulletinsRequests";
import {toast} from "react-toastify";
import {useIsLoadingActions} from "../slices/IsLoadingSlice";
import DownloadRequests from "../../api/downloader/DownloadRequests";
import DownloaderShutdownRequests from "../../api/bulletins/DownloaderShutdownRequests";



const deleteRegisters=(date:string,origin:string)=>{

    const {deleteRegisters} = BulletinsRequests

    return async ()=>{
        return await deleteRegisters(date,origin)
    }
}

const downloadAndSaveRegisters=(date:string,origin:string)=>{

    const {downloadAndSaveRegisters, } = DownloadRequests

    return async ()=>{
        return await downloadAndSaveRegisters(date,origin)
    }
}
const downloadAndSaveIncidents=(date:string,origin:string)=>{

    const {downloadAndSaveIncidents, } = DownloadRequests

    return async ()=>{
        return await downloadAndSaveIncidents(date,origin)
    }
}
const forceINPIUpdate=()=>{

    const {forceINPIUpdate, } = DownloadRequests

    return async ()=>{
        return await forceINPIUpdate()
    }
}
const shutdownDownloader=()=>{

    const {shutdownDownloader, } = DownloaderShutdownRequests

    return async ()=>{
        return await shutdownDownloader()
    }
}
export const useDownloaderThunks = () => {

    const dispatch = useAppDispatch()

    const {setIsLoading}=useIsLoadingActions()


    const handleRequest = (result:any, successMessage: string, failureMessage: string) => {
        setIsLoading(false)
        if (result) {
            toast.success(successMessage)
        } else {
            toast.error(failureMessage)
        }
    }


    return {

        deleteRegisters:(date:string,origin:string,successMessage:string,failureMessage:string)=>{
            setIsLoading(true)
            dispatch(deleteRegisters(date,origin)).then(result=>{
                handleRequest(result,successMessage,failureMessage)
            })
        },

        downloadAndSaveRegisters:(date:string,origin:string,successMessage:string,failureMessage:string)=>{
            setIsLoading(true)
            dispatch(downloadAndSaveRegisters(date,origin)).then(result=>{
                handleRequest(result,successMessage,failureMessage)
            })
        },
        downloadAndSaveIncidents:(date:string,origin:string,successMessage:string,failureMessage:string)=>{
            setIsLoading(true)
            dispatch(downloadAndSaveIncidents(date,origin)).then(result=>{
                handleRequest(result,successMessage,failureMessage)
            })
        },
        forceINPIUpdate:(successMessage:string,failureMessage:string)=>{
            setIsLoading(true)
            dispatch(forceINPIUpdate()).then(result=>{
                handleRequest(result,successMessage,failureMessage)
            })
        },
        shutdownDownloader:(successMessage:string,failureMessage:string)=>{
            setIsLoading(true)
            dispatch(shutdownDownloader()).then(result=>{
                handleRequest(result,successMessage,failureMessage)
            })
        },


    }

}