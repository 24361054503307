import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";


const RepresentativeStatisticsTable = () => {

    const {representativeCount} = useBulletinsActions()
    const representatives = representativeCount.content

    if (!representatives) return <></>

    const intl = useIntl()
    const messages = {
        name: intl.formatMessage({id: "model.name"}),
        quantity: intl.formatMessage({id: "bulletins.label.quantity"}),
        origin: "Origin"
    }

    return <>
        <Table>
            <thead>
            <tr>
                <th>{messages.name}</th>
                <th>{messages.quantity}</th>
                <th>{messages.origin}</th>
            </tr>
            </thead>
            <tbody>
            {
                representatives.map(({representativeName, count, origin}) => {
                    return <tr key={representativeName}>
                        <td>{representativeName}</td>
                        <td>{count}</td>
                        <td>{origin}</td>
                    </tr>
                })
            }
            </tbody>

        </Table>
    </>

}

export default RepresentativeStatisticsTable