import React, {useEffect} from "react";
import {Form, Spinner, Table} from "react-bootstrap";
import CustomerFormModal from "./CustomerFormModal";
import CustomerObservingClassFormModal from "./CustomerObservingClassFormModal";
import CustomerRegisterFormModal from "./CustomerRegisterFormModal";
import CustomerRegisterUpdateFormModal from "./CustomerRegisterUpdateFormModal";
import CustomerDetailsModal from "./CustomerDetailsModal";
import {Customer} from "../../models/Customer";
import {useCustomerActions} from '../../store/slices/CustomerSlice'
import {useCustomerThunks} from "../../store/thunks/CustomerThunks";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";

const Customers = () => {


    const intl = useIntl()
    const {customers, setCustomers,} = useCustomerActions()
    const {fetchCustomers,isLoading} = useCustomerThunks()

    const messages = {
        name: intl.formatMessage({id: "model.name"}),
        addNewCustomer: intl.formatMessage({id: "customers.label.addNewCustomer"}),
    }

    useEffect(() => {
        fetchCustomers()
        return () => {
            setCustomers([])
        }
    }, [])

    const {
        showNewCustomerModal,
        setShowNewCustomerModal,
        showUpdateModal,
        showDetailsModal,
        showRegisterModal,
        showObservingClassModal,
    } = useCustomerActions()
    const handleShow = () => setShowNewCustomerModal(true);

    return <>

        <div className={"p-5"}>

            {isLoading && <Spinner animation={"border"}/>}
            <MyButton label={messages.addNewCustomer} onClick={handleShow}/>
            {
                !isLoading && <>
                    {customers.length > 0 && <> <Table striped hover bordered style={{margin: 5}}>

                        <thead className={"m-0 p-0 text-center"} style={{}}>
                        <tr>
                            <th>
                                {messages.name}
                            </th>
                            <th className={"p-0"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            customers.map(customer => {
                                return <CustomerRow key={customer.id} customer={customer}/>
                            })
                        }
                        </tbody>

                    </Table>

                    </>
                    }

                </>
            }

            {showNewCustomerModal && <CustomerFormModal />}
            {showObservingClassModal && <CustomerObservingClassFormModal/>}
            {showRegisterModal && <CustomerRegisterFormModal/>}
            {showUpdateModal && <CustomerRegisterUpdateFormModal/>}
            {showDetailsModal && <CustomerDetailsModal/>}
        </div>


    </>


}


interface Props {
    customer: Customer
}

const CustomerRow = ({customer}: Props) => {
    const intl = useIntl()

    const messages = {
        details: intl.formatMessage({id: "customers.label.details"}),
        addRegister: intl.formatMessage({id: "customers.label.addRegister"}),
        updateRegisters: intl.formatMessage({id: "customers.label.updateRegisters"}),
        remove: intl.formatMessage({id: "generics.label.remove"}),
    }

    if (!customer) return <></>
    const {name} = customer

    const {removeCustomer} = useCustomerThunks()

    const {
        setSelectedCustomer,
        changeUpdateModal,
        changeRegisterModal,
        changeDetailsModal
    } = useCustomerActions()

    const setShowCustomerRegistersUpdateModal = (show: boolean) => {
        changeUpdateModal(show)
    }
    const setShowCustomerRegistersModal = (show: boolean) => {
        changeRegisterModal(show)
    }
    const setShowCustomerDetailsModal = (show: boolean) => {
        changeDetailsModal(show)
    }


    const handleRegistersAdd = (customer: Customer) => {
        setSelectedCustomer(customer)
        setShowCustomerRegistersModal(true)
    }


    const handleRegistersUpdate = (customer: Customer) => {
        setSelectedCustomer(customer)
        setShowCustomerRegistersUpdateModal(true)
    }

    const handleRemoveCustomer = async (id: number) => {
        const confirmation = window.confirm("Are you sure")
        if (confirmation) {
            removeCustomer(id)
        }
    }

    const handleDetailsChange = (customer: Customer) => {
        setSelectedCustomer(customer)
        setShowCustomerDetailsModal(true)
    }


    return <tr>
        <td><Form.Control name={"name"} value={name ?? ''} disabled/></td>
        <td>
            <MyButton className={"mx-2"} label={messages.details} onClick={() => handleDetailsChange(customer)}
                      color={Colors.GREEN}/>
            <MyButton label={messages.addRegister} onClick={() => handleRegistersAdd(customer)}/>
            <MyButton className={"mx-2"} label={messages.updateRegisters}
                      onClick={() => handleRegistersUpdate(customer)} color={Colors.GREEN}/>
            <MyButton label={messages.remove} onClick={() => handleRemoveCustomer(customer.id ?? -1)}
                      color={Colors.RED}/>
        </td>
    </tr>

}

export default Customers;