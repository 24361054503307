import React, {ChangeEvent, useEffect} from "react";
import RegisterForm from "./RegisterForm";
import {Customer} from "../../models/Customer";
import {BrandRegister} from "../../models/BrandRegister";
import {defaultCustomer, defaultRegister, useCustomerActions} from "../../store/slices/CustomerSlice";
import {SelectChangeEvent} from "@mui/material";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import {useBrandRegisterThunks} from "../../store/thunks/BrandRegisterThunks";
import {useBrandRegistersAction} from "../../store/slices/BrandRegisterSlice";


const CustomerRegisterUpdateFormModal = () => {

    const intl = useIntl()
    const messages = {
        updateDetailsOf: intl.formatMessage({id: "customers.label.updateDetailsOf"}),
        removeRegister: intl.formatMessage({id: "customers.label.removeRegister"}),
        close: intl.formatMessage({id: "generics.label.close"}),
        saveChanges: intl.formatMessage({id: "generics.label.saveChanges"}),
    }

    const {updateRegister, deleteRegister} = useBrandRegisterThunks()

    const {
        showUpdateModal, selectedCustomer, setSelectedCustomer, changeUpdateModal,
        updateCustomer, setSelectedRegister, selectedRegister
    } = useCustomerActions()

    const setShowCustomerRegistersUpdateModal = (show: boolean) => {
        changeUpdateModal(show)
    }


    const actualCustomer = (): Customer => {
        return selectedCustomer ?? defaultCustomer()
    }

    const {registers, setRegisters} = useBrandRegistersAction()

    useEffect(() => {
        const registers = actualCustomer()?.brandRegisters
        setRegisters(registers ?? [])
        if (registers && registers?.length > 0) {
            setSelectedRegister(registers[0])
        }
    }, [selectedCustomer])
    const handleClose = () => {
        setSelectedRegister({} as BrandRegister)
        setShowCustomerRegistersUpdateModal(false)
    };
    const handleRegister = (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
        const {name, value} = event.target
        const updatedRegister = {...selectedRegister, [name]: value} as BrandRegister
        setSelectedRegister(updatedRegister)
        const registerIndex = registers.findIndex(r => r.id === selectedRegister?.id)
        const updatedRegisters = [...registers]
        updatedRegisters[registerIndex] = updatedRegister
        setRegisters(updatedRegisters)
        const updatedCustomer = {...actualCustomer(), brandRegisters: updatedRegisters} as Customer
        updateCustomer(updatedCustomer)
    }
    const handleRemoveRegister = async () => {
        if (selectedRegister && selectedRegister.id) {
            deleteRegister(selectedRegister.id)
            const updatedRegisters = registers.filter(brandRegister => brandRegister.id !== selectedRegister.id)
            setRegisters(updatedRegisters)
            const updatedCustomer = {...selectedCustomer, brandRegisters: updatedRegisters}
            updateCustomer(updatedCustomer)
            setSelectedRegister(defaultRegister())
            setSelectedCustomer(updatedCustomer)
        }
    }
    const handleRegisterSelection = (event: ChangeEvent<HTMLInputElement & HTMLSelectElement> | SelectChangeEvent<any>) => {
        const selectedRegister = registers.find(register => register.id === parseInt(event.target.value))
        if (selectedRegister) {
            setSelectedRegister(selectedRegister)
        }
    }

    const handleUpdateRegister = async (register: BrandRegister) => {
        if (register && selectedCustomer.id) {
            updateRegister(selectedCustomer.id, register)
            handleClose()
        }
    }
    return (
        <RegisterForm handleRegister={handleRegister} onSelectChange={handleRegisterSelection}
                      isUpdate={true} show={showUpdateModal}
                      size={"lg"}
                      title={`${messages.updateDetailsOf} ${actualCustomer()?.name}`} actions={
            [
                {label: messages.removeRegister, action: handleRemoveRegister, color: Colors.RED, isSubmit: false},
                {label: messages.close, action: handleClose, color: Colors.GRAY, isSubmit: false},
                {label: messages.saveChanges, action: handleUpdateRegister, color: Colors.BLUE, isSubmit: true}
            ]
        }/>
    )
}

export default CustomerRegisterUpdateFormModal;