import {BrandRegister} from "../../models/BrandRegister";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../hooks";


export interface BrandRegisterState{
    registers:BrandRegister[],
}

const brandRegisterState:BrandRegisterState={
    registers: [],
}

const brandRegisterSlice=createSlice({
    name:"brandRegisters",
    initialState:brandRegisterState,
    reducers:{
        setRegisters:(state, action:PayloadAction<BrandRegister[]>)=>{
            state.registers=action.payload
        }
    }
})

export default brandRegisterSlice.reducer


export const useBrandRegistersAction=()=>{

    const {registers}=useAppSelector(state=>state.brandRegisters)
    const {setRegisters}=brandRegisterSlice.actions

    const dispatch = useAppDispatch()


    return {

        registers,

        setRegisters:(registers:BrandRegister[])=>{
            dispatch(setRegisters(registers))
        },

    }

}