import {requests} from "./API";
import {Similarity} from "../../models/Similarity";
import {SimilarityDto} from "../../models/SimilarityDto";
import {SimpleRegister} from "../../models/SimpleRegister";


const SimilarityRequests = {
    getSimilaritiesWithSource: (startDate:string, endDate:string, origin:string) => requests.get<SimilarityDto>(`/similarity/${startDate}/${endDate}/${origin}`),
    getSimilaritiesWithSourceFromSelectedRegisters: (startDate:string, endDate:string, origin:string,simpleRegisters:SimpleRegister[]) => requests.post<SimilarityDto,SimpleRegister[]>(`/similarity/${startDate}/${endDate}/${origin}`,simpleRegisters),
    freeSearch: (brandText:string, observingClass:number, startDate:string, endDate:string) => requests.get<Similarity[]>(`/similarity/${brandText}/${observingClass}/${startDate}/${endDate}`),
    generateReport: (similarities:Similarity[], startDate:string, endDate:string, origin:string) => requests.downloadPost(`/similarity/report/${startDate}/${endDate}/${origin}`, similarities),
}

export default SimilarityRequests