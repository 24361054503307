import React, {useEffect, useState} from "react";
import {Renewal} from "../../models/Renewal";
import {useRenewalThunks} from "../../store/thunks/RenewalThunks";
import {toast} from "react-toastify";
import RenewalsTable from "../renewals/RenewalsTable";

const NextRenewals = () => {

    const [renewals, setRenewals] = useState([] as Renewal[])
    const [isLoading,setIsLoading]=useState(false)
    const months = 6

    const {fetchRenewalsByMonth} = useRenewalThunks()

    useEffect(() => {
        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este período")
                }
                setRenewals(result)
            }

        }
        setIsLoading(true)
        fetchRenewalsByMonth(months, handleResult)
        setIsLoading(false)
    }, [])

    return <>
        {!isLoading && <RenewalsTable renewalData={renewals}/>}
    </>
}

export default NextRenewals