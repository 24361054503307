import {useState} from "react";
import { Col, Container, Row, Spinner} from "react-bootstrap";
import {Button} from "@mui/material"

import dayjs from "dayjs";
import MyDatePicker from "../../widgets/MyDatePicker";
import {Incident} from "../../models/Incident";
import {useIncidentThunks} from "../../store/thunks/IncidentThunks";
import MyTextInput from "../../widgets/MyTextInput";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";

const SearchIncidents = () => {

    const [processNumber, setProcessNumber] = useState('');
    const [startDate, setStartDate] = useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirst, setIsFirst] = useState(true);
    const {fetchIncidentsByNumber} = useIncidentThunks()

    const intl=useIntl()
    const messages={
        number:intl.formatMessage({id:"model.number"}),
        searchIncidents:intl.formatMessage({id:"generics.label.search"}),
        startDate:intl.formatMessage({id:"generics.label.startDate"}),
        endDate:intl.formatMessage({id:"generics.label.endDate"}),
    }

    const handleGetIncident = async () => {
        setIsFirst(false)
        setIncidents([])
        setIsLoading(true)
        const handleResult = (result: Incident[] | undefined) => {
            if (result) {
                setIncidents(result)
            }
            setIsLoading(false)
        }
        fetchIncidentsByNumber(processNumber, startDate, endDate, handleResult)
    }


    return <>
        <Container>
            <div className={"mt-3"}>
                <MyTextInput label={messages.number} value={processNumber} onChange={(event) => setProcessNumber(event.target.value)} />
                <Row className={"mt-3"}>
                    <Col>
                        <MyDatePicker label={messages.startDate} date={startDate} onChangeDate={(date) => setStartDate(date)}/>
                    </Col>
                    <Col>
                        <MyDatePicker label={messages.endDate} date={endDate} onChangeDate={(date) => setEndDate(date)}/>
                    </Col>

                </Row>
            </div>
            <div className={"mt-3"}>
                <MyButton label={messages.searchIncidents} onClick={handleGetIncident} />
            </div>
            {isLoading && <Spinner animation={"border"}/>}

            {
                (incidents.length === 0 && !isLoading && !isFirst) && <div>Sem resultados</div>
            }
            {
                incidents.map(
                    ({processNumber, image}) => {
                        return <>
                            <div> Processo: {processNumber}</div>
                            <figure>
                                <img style={{borderStyle: "solid", borderColor: "black", borderWidth: "1px"}}
                                     alt={`Incident for ${processNumber}`} width={1200}
                                     src={`data:image/png;base64,${image}`}/>
                            </figure>
                        </>
                    }
                )

            }
        </Container>
    </>
}
export default SearchIncidents