import {Container} from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import {AccordionItems} from "../../models/AccordionItems";
import {useKeycloak} from "@react-keycloak/web";
import {mapInstitute} from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import {useIntl} from "react-intl";
import IncidentForm from "./IncidentForm";
import NextRenewals from "./NextRenewals";
import {useIncidentsWatchTaskActions} from "../../store/slices/IncidentsWatchTaskSlice";


const Incidents = () => {
    const {keycloak} = useKeycloak()
    const {selectedInstitute, setSelectedInstitute} = useIncidentsWatchTaskActions()
    const intl = useIntl()
    const messages = {
        next6MonthRenewals: intl.formatMessage({id: "incidents.label.next6MonthRenewals"}),
        institute: intl.formatMessage({id: "model.institute"}),
    }

    const items = (): AccordionItems[] => {
        return [
            {header: messages.next6MonthRenewals, body: <NextRenewals/>},
        ]

    }


    return <>
        <Container className={"mt-2"}>

            <MyAccordion items={items()} className={"mb-2"}/>
            <MySelect onChange={(evt) => setSelectedInstitute(evt.target.value)} labelId={"insitutes-dropdown-label"}
                      label={messages.institute} value={selectedInstitute} className={"mb-2"}
                      selectId={"institutes-label"}
                      data={
                          keycloak.tokenParsed?.institutes?.map((inst: string) => {
                              const institute = mapInstitute(inst)
                              if (institute) {
                                  return <option key={institute.origin}
                                                 value={institute.origin}>{institute.name}</option>
                              }
                          })
                      }/>
        </Container>

        {
            selectedInstitute && <IncidentForm/>
        }
    </>
}

export default Incidents
