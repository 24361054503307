import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IncidentWatchTask} from "../../models/IncidentWatchTask";
import {useAppDispatch, useAppSelector} from "../hooks";
import dayjs from "dayjs";
import {friendlyDate} from "../../utils/DateUtils";
import {Incident} from "../../models/Incident";

interface IncidentsWatchTaskState {
    watchTaskList: IncidentWatchTask[],
    startDate: string,
    incidents: Incident[],
    isFirst: boolean,
    selectedInstitute: string,
}

const incidentsWatchTaskState: IncidentsWatchTaskState = {
    watchTaskList: [],
    startDate: friendlyDate(dayjs()),
    incidents: [],
    isFirst: true,
    selectedInstitute: 'PT',
}

const isFirstUpdate = (watchTaskList: IncidentWatchTask[]) => {
    return watchTaskList.length === 0
}

const isStale = (task: IncidentWatchTask, newTasks: IncidentWatchTask[]) => {
    return newTasks.find(newTask => newTask.dateWatch === task.dateWatch && newTask.origin === task.origin)
}

const incidentsWatchTaskSlice = createSlice({
        name: "incidentsWatchTask",
        initialState: incidentsWatchTaskState,
        reducers: {

            setWatchTaskLists: (state, action: PayloadAction<IncidentWatchTask[]>) => {
                state.watchTaskList = action.payload
            },
            clearState: () => incidentsWatchTaskState,
            updateWatchTaskList: (state, action: PayloadAction<IncidentWatchTask[]>) => {
                const newTasks = action.payload
                if (newTasks.length === 0) return

                const newTasksWithoutWeekend = newTasks.filter(task => {
                    const taskDay = dayjs(task.dateWatch).day()
                    return taskDay !== 0 && taskDay !== 6
                })

                if (isFirstUpdate(newTasks)) {
                    state.watchTaskList = newTasksWithoutWeekend
                } else {
                    const currentTasks = state.watchTaskList

                    const notStale = currentTasks?.filter(task => !isStale(task, newTasksWithoutWeekend)) ?? []
                    state.watchTaskList = [...notStale, ...newTasksWithoutWeekend]
                }
            }, setStartDate: (state, action: PayloadAction<string>) => {
                state.startDate = action.payload
            }, setIncidents: (state, action: PayloadAction<Incident[]>) => {
                state.incidents = action.payload
            }, setIsFirst: (state, action: PayloadAction<boolean>) => {
                state.isFirst = action.payload
            }, setSelectedInstitute: (state, action: PayloadAction<string>) => {
                state.selectedInstitute = action.payload
            },

        }
    }
)

export default incidentsWatchTaskSlice.reducer
export const useIncidentsWatchTaskActions = () => {

    const {
        updateWatchTaskList, clearState,
        setWatchTaskLists, setStartDate,
        setIncidents, setIsFirst, setSelectedInstitute,
    } = incidentsWatchTaskSlice.actions
    const dispatch = useAppDispatch()
    const {
        watchTaskList, startDate, incidents, isFirst,selectedInstitute
    } = useAppSelector(state => state.incident)

    return {
        watchTaskList, startDate, incidents, isFirst,selectedInstitute,

        updateWatchTaskList: (incident: IncidentWatchTask[]) => {
            dispatch(updateWatchTaskList(incident))
        },
        setWatchTaskLists: (incidentWatchTasks: IncidentWatchTask[]) => {
            dispatch(setWatchTaskLists(incidentWatchTasks))
        },
        clearState: () => {
            dispatch(clearState())
        },
        setStartDate: (startDate: string) => {
            dispatch(setStartDate(startDate))
        },
        setIncidents: (incidents: Incident[]) => {
            dispatch(setIncidents(incidents))
        }, setIsFirst: (isFirst: boolean) => {
            dispatch(setIsFirst(isFirst))
        },
        setSelectedInstitute:(selectedInstitute:string)=>{
            dispatch(setSelectedInstitute(selectedInstitute))
        },
    }


}