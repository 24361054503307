import KeycloakRequests from "../../api/customer/KeycloakRequests";
import React from "react";
import {Spinner, Table} from "react-bootstrap"
import MyTextInput from "../../widgets/MyTextInput";
import {Box, FormControl,} from "@mui/material";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {KeycloakGroup} from "../../models/keycloak/KeycloakGroup";
import {KeycloakUser} from "../../models/keycloak/KeycloakUser";
import {useIntl} from "react-intl";
import {object, string} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useKeycloakThunks} from "../../store/thunks/KeycloakThunks";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useKeycloakAction} from "../../store/slices/KeycloakSlice";
const TeamSchema = object().shape({
    name: string().required(),
}).required()

const KeycloakTeamManagement = () => {

    const {
        register,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(TeamSchema)
    })


    const {setGroups, groups} = useKeycloakAction()
    const {createGroup, updateGroupInstitute, deleteGroup} = useKeycloakThunks()

    const {isLoading, setIsLoading} = useIsLoadingActions()

//    const [isLoading, setIsLoading] = useState(false)

    const intl = useIntl()
    const messages = {
        newTeam: intl.formatMessage({id: "keycloak.label.newTeam"}),
        name: intl.formatMessage({id: "model.name"}),
        createTeam: intl.formatMessage({id: "keycloak.label.createTeam"}),
        institute: intl.formatMessage({id: "model.institute"}),
        updateInstitutes: intl.formatMessage({id: "keycloak.label.updateInstitutes"}),
        remove: intl.formatMessage({id: "generics.label.remove"}),
    }

    const institutes = () => {
        return ["PT", "EU", "ES", "BR"]
    }

    const updateInstitute = async (group: KeycloakGroup) => {
        if (group.id) {
            updateGroupInstitute(group)
        }
    }


    const handleInstituteChange = (evt: React.ChangeEvent<HTMLInputElement>, group: KeycloakGroup) => {
        const institute = evt.target.value
        let institutes: string[]
        if (!group.attributes.institute) {
            group.attributes.institute = []
        }
        if (group.attributes.institute.includes(institute)) {
            institutes = group.attributes.institute.filter(i => i != institute)
        } else {
            institutes = [...group.attributes.institute, institute]
        }

        const changedGroup = {...group, attributes: {institute: institutes}}
        const newGroups = [...groups]
        const changedGroupIndex = newGroups.findIndex(g => g.name === group.name)
        newGroups[changedGroupIndex] = changedGroup
        setGroups(newGroups)
    }

    const handleCreateTeam = async (keycloakGroup: KeycloakGroup) => {
        setIsLoading(true)
        const group = {...keycloakGroup, path: `/${keycloakGroup.name}`, attributes: {institute: []}} as KeycloakGroup
        createGroup(group)
    }

    const handleRemoveGroup = async (group: KeycloakGroup) => {
        if (group.id) {
            deleteGroup(group)
        }
    }

    return <>
        {isLoading && <Spinner animation={"border"}/>}
        <div className={"m-2"}>
            <FormControl>
                <Box>
                    <h4>{messages.newTeam}</h4>

                    <MyTextInput label={messages.name} {...register("name")}/>
                    <div className={"mt-2"}>
                        <MyButton label={messages.createTeam}
                                  onClick={handleSubmit((data) => handleCreateTeam(data as KeycloakGroup))}/>
                    </div>
                </Box>
            </FormControl>
        </div>


        <Table striped bordered hover>
            <thead>
            <tr>
                <th>{messages.name}</th>
                <th>{messages.institute}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {groups.filter(t => t.name !== '').map(team => (
                <tr key={team.name}>
                    <td>{team.name}</td>
                    <td align={"center"}>
                        {institutes().map(institute => <React.Fragment key={institute}>
                            <input className={"ms-2"} type={"checkbox"}
                                   onChange={(evt) => handleInstituteChange(evt, team)}
                                   value={institute} checked={team.attributes?.institute?.includes(institute)}/>
                            <label>{institute}</label>
                        </React.Fragment>)}

                        <MyButton className={"ms-2"} label={messages.updateInstitutes}
                                  onClick={() => updateInstitute(team)}
                                  color={Colors.GREEN}/>
                    </td>
                    <td align={"center"}>
                        <MyButton label={messages.remove} onClick={() => handleRemoveGroup(team)} color={Colors.RED}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </>
}

export default KeycloakTeamManagement