import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Renewal} from "../../models/Renewal";
import {useAppDispatch, useAppSelector} from "../hooks";
import dayjs from "dayjs";

interface RenewalState {
    renewalsByYear: Renewal[],
    yearRenewal: number,
    renewalsByMonth: Renewal[],
    monthRenewal: number
}

const renewalState: RenewalState = {
    renewalsByYear: [],
    renewalsByMonth: [],
    monthRenewal: 1,
    yearRenewal: dayjs().year(),
}

const renewalSlice = createSlice({
    name: "renewal",
    initialState: renewalState,
    reducers: {

        setMonthRenewals: (state, action: PayloadAction<Renewal[]>) => {
            state.renewalsByMonth = action.payload
        },
        setYearRenewals: (state, action: PayloadAction<Renewal[]>) => {
            state.renewalsByYear = action.payload
        },
        setMonth: (state, action: PayloadAction<number>) => {
            state.monthRenewal = action.payload
        },
        setYear: (state, action: PayloadAction<number>) => {
            state.yearRenewal = action.payload
        },
        clearState: () => {
            return renewalState
        }
    }
})


export default renewalSlice.reducer


export const useRenewalsActions=()=>{

    const dispatch = useAppDispatch()
    const {renewalsByYear,yearRenewal,monthRenewal,renewalsByMonth}=useAppSelector(state=>state.renewal)

    const {setMonthRenewals,setYearRenewals,setYear,setMonth,clearState}=renewalSlice.actions

    return {

        renewalsByYear,renewalsByMonth,
        yearRenewal,monthRenewal,

        setMonth:(month:number)=>{
            dispatch(setMonth(month))
        },
        setYear:(year:number)=>{
            dispatch(setYear(year))
        },
        setRenewalsByYear:(renewals:Renewal[])=>{
            dispatch(setYearRenewals(renewals))
        },
        setRenewalsByMonth:(renewals:Renewal[])=>{
            dispatch(setMonthRenewals(renewals))
        },
    }

}
