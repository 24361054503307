import React, {useEffect} from "react";
import KeycloakTeamManagement from "./KeycloakTeamManagement";
import MyAccordion from "../../widgets/MyAccordion";
import {AccordionItems} from "../../models/AccordionItems";
import {useIntl} from "react-intl";
import KeycloakUserManagement from "./KeycloakUserManagement";
import {Spinner} from "react-bootstrap";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useKeycloakThunks} from "../../store/thunks/KeycloakThunks";

const KeycloakView = () => {

    const {isLoading} = useIsLoadingActions()
    const {fetchKeycloakData} = useKeycloakThunks()

    const intl = useIntl()
    const messages = {
        teamManagement: intl.formatMessage({id: "keycloak.label.teamManagement"}),
        userManagement: intl.formatMessage({id: "keycloak.label.userManagement"}),
    }

    useEffect(() => {
        fetchKeycloakData()
    }, [])

    const items = (): AccordionItems[] => {
        return [
            {
                header: messages.teamManagement, body:
                    <KeycloakTeamManagement/>
            },
            {
                header: messages.userManagement,
                body: <KeycloakUserManagement/>
            }
        ]
    }

    return <>
        <h1>Keycloak Management</h1>
        {isLoading && <Spinner animation={"border"}/>}
        {!isLoading && <MyAccordion items={items()}/>}
    </>
}


export default KeycloakView;