import {Modal} from "react-bootstrap";
import React from "react";

interface Props {
    show:boolean,
    size:"sm"|"lg"|"xl"|undefined,
    title:string,
    body:JSX.Element,
    footer:JSX.Element,
    handleClose:()=>void
}

const MyModal = ({show, size, title, body, footer, handleClose}: Props) => {

    return <Modal show={show} onHide={handleClose} onExited={handleClose} size={size}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}
        </Modal.Body>
        <Modal.Footer>
            {footer}
        </Modal.Footer>
    </Modal>

}

export default MyModal;