import {ChangeEvent} from "react";
import MyTextInput from "../../widgets/MyTextInput";
import RenewalsTable from "./RenewalsTable";
import {toast} from "react-toastify";
import {Renewal} from "../../models/Renewal";
import {useRenewalThunks} from "../../store/thunks/RenewalThunks";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {useRenewalsActions} from "../../store/slices/RenewalSlice";

const RenewalsByMonth = () => {

    const {fetchRenewalsByMonth} = useRenewalThunks()
    const {monthRenewal, setMonth, setRenewalsByMonth, renewalsByMonth} = useRenewalsActions()

    const intl = useIntl()
    const messages = {
        monthsInAdvance: intl.formatMessage({id: "renewals.label.monthsInAdvance"}),
        search: intl.formatMessage({id: "generics.label.search"}),
    }

    const handleMonthSearch = async () => {

        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este período")
                }
                setRenewalsByMonth(result)
            }
        }

        if (monthRenewal > 0) {
            fetchRenewalsByMonth(monthRenewal, handleResult)
        } else {
            toast("Valor inválido para meses")
        }
    }

    const handleMonthChange = (event: ChangeEvent<HTMLInputElement>) => {
        const month = parseInt(event.target.value)
        if (!isNaN(month)) {
            setMonth(month)
        }
    }


    return <>
        <MyTextInput type={"number"} inputClassName={"w-25"} value={monthRenewal}
                     onChange={handleMonthChange} label={messages.monthsInAdvance}/>
        <MyButton className={"mt-2 mb-2 ms-2"} label={messages.search} onClick={handleMonthSearch}/>
        <RenewalsTable renewalData={renewalsByMonth}/>

    </>
}
export default RenewalsByMonth