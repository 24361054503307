import {useAppDispatch} from "../hooks";
import RenewalRequests from "../../api/customer/RenewalRequests";
import {Renewal} from "../../models/Renewal";


const fetchRenewalsByYear = (year: number) => {

    const {getRenewalByYear} = RenewalRequests

    return async () => {
        return await getRenewalByYear(year)
    }
}
const fetchRenewalsByMonth = (month: number) => {

    const {getRenewalByMonthProximity} = RenewalRequests

    return async () => {
        return await getRenewalByMonthProximity(month)
    }
}

export const useRenewalThunks = () => {
    const dispatch = useAppDispatch()

    return {
        fetchRenewalsByYear: (year: number, handleResult: (renewals: Renewal[]) => void) => {
            dispatch(fetchRenewalsByYear(year)).then(r => handleResult(r))
        },
        fetchRenewalsByMonth: (month: number, handleResult: (renewals: Renewal[]) => void) => {
            dispatch(fetchRenewalsByMonth(month)).then(r => handleResult(r))
        },
    }
}