import React from "react";
import {Accordion} from "react-bootstrap";
import {AccordionItems} from "../models/AccordionItems";

interface Props{
    items:AccordionItems[],
    className?:string|undefined,
    defaultAsOpen?:boolean,
}

const MyAccordion = ({items,className=undefined,defaultAsOpen=true}:Props)=>{

    return <>
        <Accordion defaultActiveKey={defaultAsOpen?"0":undefined} className={className}>
            {items.map((item,index)=>{
                return <React.Fragment key={index}>
                    <Accordion.Item eventKey={index.toString()} >
                        <Accordion.Header>{item.header}</Accordion.Header>
                        <Accordion.Body>{item.body}</Accordion.Body>
                    </Accordion.Item>
                </React.Fragment>
            })}
        </Accordion>

    </>
}
export default MyAccordion