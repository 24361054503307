import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import keycloak from "./keycloak/Keycloak";

import {Provider} from "react-redux";
import store from "./store/store";
import {ReactKeycloakProvider} from "@react-keycloak/web";


const root = ReactDOM.createRoot(
    document.getElementById('root') ?? document.body
);
root.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
