import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import {friendlyDate} from "../utils/DateUtils";
import dayjs, {Dayjs} from "dayjs";
import {WatchTask} from "../models/WatchTask";
import React from "react";
import {Form} from "react-bootstrap";
import {useSimilarityThunks} from "../store/thunks/SimilarityThunks";
import {useWatchTaskActions} from "../store/slices/WatchTaskSlice";

interface Props {
    date: dayjs.Dayjs,
    onChangeDate: (date: Dayjs) => void,
    onMonthChange?: (month: Dayjs,) => void,
    taskWatchList?: WatchTask[],
    isIncident?: boolean,
    label?: string,
}

const isWeekend = (date: dayjs.Dayjs) => {
    return date.day() === 0 || date.day() === 6
}

const MyDatePicker = ({
                          date, onChangeDate, isIncident = false, label
                      }: Props) => {

    const {onMonthChange} = useSimilarityThunks()
    const {watchTaskList} = useWatchTaskActions()

    const renderWeekPickerDay = (date: dayjs.Dayjs, _: Dayjs[], pickersDayProps: PickersDayProps<Dayjs>): JSX.Element => {

        const tasks = getWatchTaskFromListByDate(date)
        if (tasks.length > 0) {
            const hasNotClosedTask = tasks.find(t => !t.closeUser)
            const style = (!hasNotClosedTask || isIncident) ? {bgcolor: 'green'} : {bgcolor: 'yellow'}
            return <PickersDay key={date.toISOString()} sx={style} {...pickersDayProps} />
        } else {
            if (isWeekend(date)) {
                return <React.Fragment key={date.toISOString()}/>
            }
            if (date.isBefore(dayjs())) {
                return <PickersDay
                    key={date.toISOString()}
                    sx={{bgcolor: 'red'}}
                    {...pickersDayProps} />
            }
            return <PickersDay key={date.toISOString()} {...pickersDayProps} />

        }

    };

    const getWatchTaskFromListByDate = (date: dayjs.Dayjs): WatchTask[] => {
        return watchTaskList?.filter(task => task.dateWatch === friendlyDate(date)) ?? []
    }

    return <>
        <Form.Label>{label}</Form.Label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker displayStaticWrapperAs="desktop"
                              onMonthChange={onMonthChange}
                              value={date} onChange={(date) => onChangeDate(date ?? dayjs())}
                              renderDay={renderWeekPickerDay}
                              renderInput={(params: any) => {
                                  return <TextField{...params} />
                              }}
            />
        </LocalizationProvider>

    </>
}

export default MyDatePicker