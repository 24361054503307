import {requests} from "./API";
import {RepresentativeCount} from "../../models/RepresentativeCount";
import {TitularCount} from "../../models/TitularCount";
import {Page} from "../../models/Page";

const baseUrl="/statistics"
const StatisticsRequests = {
    getTitularWithoutRepresentatives: (page:number) => requests.get<Page<TitularCount[]>>(`${baseUrl}/titulars?page=${page}`),
    getRepresentativesBrandsCounts: (page:number) => requests.get<Page<RepresentativeCount[]>>(`${baseUrl}/representatives?page=${page}`),
}

export default StatisticsRequests;