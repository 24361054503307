import {AppDispatch} from "../store";
import UserRequests from "../../api/customer/UserRequests";
import {clearTeamState, setTeams, useTeamActions} from "../slices/TeamSlice";
import {clearWatchTaskState, setWatchTaskList} from "../slices/WatchTaskSlice";

import TeamRequests from "../../api/customer/TeamRequests";
import {clearUserState, useUserActions,} from "../slices/UserSlice";
import {clearSimilaritiesState} from "../slices/SimilaritySlice";
import {clearRepresentativeState} from "../slices/RepresentativeSlice";
import {useAppDispatch} from "../hooks";
import Keycloak from "keycloak-js";
import {useIncidentsWatchTaskActions} from "../slices/IncidentsWatchTaskSlice";
import {useIsLoadingActions} from "../slices/IsLoadingSlice";

export const useAuthentication = () => {
    const dispatch = useAppDispatch()


    const {clearState: clearIncidentsWatchTaskState, setWatchTaskLists} = useIncidentsWatchTaskActions()
    const {setCurrentTeam}=useTeamActions()
    const {username}=useUserActions()

    const doPostLogin = (keycloak: Keycloak) => {
        const {getUserDetails} = UserRequests
        const {getTeams} = TeamRequests

        return async (dispatch: AppDispatch) => {
            const updateSession = async () => {
                const userDetails = await getUserDetails()
                if (userDetails) {
                    dispatch(setWatchTaskList(userDetails.similarityWatchTasks ?? []))
                    setWatchTaskLists(userDetails.incidentWatchTasks ?? [])
                }

                const userIsAdmin = keycloak?.tokenParsed?.realm_access?.roles.includes("admin") ?? false
                const userIsManager = keycloak?.tokenParsed?.realm_access?.roles.includes("manager") ?? false

                if (userIsAdmin) {
                    const teams = await getTeams()
                    if (teams) {
                        dispatch(setTeams(teams))

                        const team=teams.find(t=>t.users.find(u=>u.username===username))
                        if(team){
                            setCurrentTeam(team)
                        }
                    }
                } else if (userIsManager) {
                    const team = userDetails.teamDto
                    if (team) {
                        dispatch(setTeams([team]))
                    }
                    setCurrentTeam(team)
                }
            }

            await updateSession()
        }
    }

    const doLogout = () => {


        return async (dispatch: AppDispatch) => {
            dispatch(clearUserState())
            clearIncidentsWatchTaskState()
            dispatch(clearRepresentativeState())
            dispatch(clearTeamState())
            dispatch(clearSimilaritiesState())
            dispatch(clearWatchTaskState())

        }
    }

    const {setIsLoading} = useIsLoadingActions()

    return {
        doLogout: () => {
            dispatch(doLogout())
        },
        doPostLogin: (keycloak: Keycloak) => {
            setIsLoading(true)
            dispatch(doPostLogin(keycloak))
                .finally(() => setIsLoading(false))
        },
    }
}