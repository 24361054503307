import {toast} from "react-toastify";
import dayjs, {Dayjs} from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import {useSimilarityThunks} from "../../store/thunks/SimilarityThunks";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import {useSimilarityActions} from "../../store/slices/SimilaritySlice";
import StripedDataGrid from "../../widgets/StripedDataGrid";
import RegisterClassModal from "../../widgets/RegisterClassModal";
import AnalysisModal from "./modals/AnalysisModal";
import ClassesModal from "./modals/ClassesModal";
import React, {useEffect, useState} from "react";
import {Similarity} from "../../models/Similarity";
import {GridColumns, GridSelectionModel} from "@mui/x-data-grid";
import {ImageList, ImageListItem} from "@mui/material";
import {RegisterClass} from "../../models/RegisterClass";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";


dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

type Props = {
    startDate: Dayjs,
    endDate: Dayjs,
}

interface ModalRegister {
    classes: RegisterClass[],
    watched: string,
    candidate: string,
}

interface RowParams {
    row: Similarity
}

const defaultModalRegister = () => {
    return {} as ModalRegister
}

const SimilarityTable = ({startDate, endDate}: Props) => {

    const [showClassModal, setShowClassModal] = useState(false)
    const [showAnalysisModal, setShowAnalysisModal] = useState(false)
    const [showClassesModal, setShowClassesModal] = useState(false)
    const [modalRegister, setModalRegister] = useState<ModalRegister>(defaultModalRegister())
    const [similarityDetails, setSimilarityDetails] = useState<Similarity>()
    const [pageSize, setPageSize] = useState(100);

    const {
        similarities, isFirst,
         filter, origin, setSelectedSimilaritiesId, selectedSimilaritiesId
    } = useSimilarityActions()
    const {isLoading}=useIsLoadingActions()

    const {generateReport, closeWatch} = useSimilarityThunks()

    useEffect(() => {
        return () => {
            setSelectedSimilaritiesId([])
        }
    }, [])


    const intl = useIntl()
    const messages = {
        closeWatch: intl.formatMessage({id: "similarities.label.closeWatch"}),
        generateReport: intl.formatMessage({id: "similarities.label.generateReport"}),
        noResults: intl.formatMessage({id: "generics.label.noResults"}),
        similarity: intl.formatMessage({id: "similarities.label.similarity"}),
        reference: intl.formatMessage({id: "model.reference"}),
        number: intl.formatMessage({id: "model.number"}),
        brandText: intl.formatMessage({id: "model.brandText"}),
        image: intl.formatMessage({id: "model.image"}),
        classes: intl.formatMessage({id: "generics.label.classes"}),
        publication: intl.formatMessage({id: "similarities.label.publication"}),
        titular: intl.formatMessage({id: "model.titular"}),
        institute: intl.formatMessage({id: "model.institute"}),
        analysis: intl.formatMessage({id: "similarities.label.analysis"}),
    }

    const filterData = () => {
        return similarities.filter(watchedBrand => watchedBrand.probability >= filter)

    }
    const handleCloseWatch = () => {
        closeWatch(startDate, endDate, origin)

    }
    const handleGenerateReport = async () => {
        if (startDate <= endDate) {
            generateReport(startDate, endDate, origin,)
        } else {
            toast("Data fim tem de ser posterior à data de início")

        }
    }

    const handleModalAnalysisDetails = (similarity: Similarity) => {
        setShowAnalysisModal(true)
        setModalRegister({
            candidate: similarity.candidate,
            watched: similarity.watched,
            classes: similarity.offendingClasses
        } as ModalRegister)
    }

    const watchedDecoration = (watchedInfo: string) => {
        return <p>{watchedInfo}</p>
    }

    const offendingDecoration = (offendingInfo: string) => {
        return <p><b>{offendingInfo}</b></p>
    }

    const rowInfo = (watchedInfo: string, offendingInfo: string) => {
        return <div>
            {watchedDecoration(watchedInfo)}
            <br/>
            {offendingDecoration(offendingInfo)}
        </div>
    }

    const showClassDetails = (similarity: Similarity) => {
        setSimilarityDetails(similarity)
        setShowClassesModal(true)
    }


    const columns: GridColumns = [
        {
            field: "probability", headerName: messages.similarity, width: 100,
            renderCell: (params: RowParams) => {
                return `${(params.row.probability * 100).toFixed(2)} %`
            }
        },
        {
            field: "reference", headerName: messages.reference, width: 200, renderCell: (params: RowParams) => {
                return <>
                    <p>
                        {params.row.reference}
                    </p>
                </>
            },
        },
        {
            field: "number", headerName: messages.number, width: 100,
            renderCell: (params: RowParams) => {
                const {watchedNumber, offendingNumber} = params.row

                return <>
                    {rowInfo(watchedNumber, offendingNumber)}
                </>
            }
        },
        {
            field: "brandText", headerName: messages.brandText, width: 250,
            renderCell: (params: RowParams) => {
                const {brandText, originalOffendingBrand} = params.row
                return <>
                    {rowInfo(brandText, originalOffendingBrand)}
                </>
            }
        },
        {
            field: "images", headerName: messages.image, width: 400,
            renderCell: (params: RowParams) => {
                const {watchedImage, offendingImage, brandText, originalOffendingBrand} = params.row
                const images = [watchedImage, offendingImage].filter(image => image)
                    .map(image => <ImageListItem key={image}>
                        <img src={`data:image/png;base64,${image}`} alt={""}/>
                    </ImageListItem>)

                return <>
                    <div>
                        {!watchedImage && watchedDecoration(brandText)}
                        {images.length > 0 && <ImageList cols={1} >
                            {images}
                        </ImageList>}
                        <br/>
                        {!offendingImage && offendingDecoration(originalOffendingBrand)}
                    </div>
                </>
            }
        },
        {
            field: "classes", headerName: messages.classes, width: 150,
            renderCell: (params: RowParams) => {

                const {watchedClasses, offendingClasses} = params.row
                const watchedInfo = watchedClasses.map((clazz) => {
                    return `${clazz.key}; `
                })
                const offendingInfo = offendingClasses.map((clazz) => {
                    return `${clazz.key}; `
                })
                return <>
                    <div>
                        <p>{watchedInfo}</p>
                        <br/>
                        <p><b>{offendingInfo}</b></p>
                        <MyButton label={"Detalhes"} onClick={() => showClassDetails(params.row)}/>
                    </div>
                </>
            }
        },
        {
            field: "datePublication", headerName: messages.publication, width: 100, renderCell: (params: RowParams) => {
                const {datePublication} = params.row
                return <>
                    <b>{datePublication}</b>
                </>
            }
        },
        {
            field: "titulars", headerName: messages.titular, width: 300,
            renderCell: (params: RowParams) => {
                const {watchedTitular, offendingTitular} = params.row
                return <>
                    {rowInfo(watchedTitular, offendingTitular)}
                </>
            }
        },
        {
            field: "watchedSource", headerName: messages.institute, renderCell: (params: RowParams) => {
                const {watchedSource, offendingSource} = params.row
                return <>
                    {rowInfo(watchedSource, offendingSource)}
                </>
            }
        },
        {
            field: "similarity", headerName: messages.analysis, renderCell: (params: RowParams) => {
                return <MyButton label={"info"} onClick={() => handleModalAnalysisDetails(params.row)}/>
            }
        },
    ]

    const rows = filterData()?.map((similarity, index) => {
        return {...similarity, id: index} as Similarity
    })

    const onSelectionChange = (selectionModel: GridSelectionModel) => {
        const selectedIds = selectionModel.map(v => (v) as number)
        setSelectedSimilaritiesId(selectedIds)
    }

    const getWatchedInfo = () => {
        return similarityDetails?.watchedClasses.map((rc,index) => {
            return <p key={index}>{rc.key} {rc.value}</p>
            }
        )
    }

    const getOffendingInfo = () => {
        return similarityDetails?.offendingClasses.map((rc,index) => {
            return <p key={index}>{rc.key} {rc.value}</p>
            }
        )
    }

    return <>
        {(!isFirst && !isLoading) &&
            <MyButton label={messages.closeWatch} onClick={handleCloseWatch} className={"mb-2"}
                      color={Colors.GREEN}/>}

        {similarities.length > 0 &&
            <>
                <br/>
                <MyButton label={messages.generateReport} onClick={handleGenerateReport} className={"mb-2"}/>
                <StripedDataGrid

                    sx={{
                        '& .MuiDataGrid-virtualScroller': {
                            transform: 'rotateX(180deg)',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            transform: 'rotateX(180deg)',
                        },
                    }}


                    autoHeight
                    getRowHeight={() => 'auto'}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    onSelectionModelChange={onSelectionChange}
                    selectionModel={selectedSimilaritiesId}
                    disableSelectionOnClick
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }

                />

                <RegisterClassModal show={showClassModal} setShow={setShowClassModal} classes={modalRegister.classes}/>
                <AnalysisModal show={showAnalysisModal} setShow={setShowAnalysisModal}
                               brandTexts={[modalRegister.watched, modalRegister.candidate]}/>
                <ClassesModal show={showClassesModal} setShow={setShowClassesModal} watchedInfo={getWatchedInfo()}
                              offendingInfo={getOffendingInfo()}/>
            </>

        }
        {(similarities.length === 0 && !isFirst && !isLoading) && <div>{messages.noResults}</div>}
    </>


}


export default SimilarityTable;