import React from "react";
import {FormattedMessage} from "react-intl";

const Home = () => {

    return <>
        <h1>
            <FormattedMessage id={"nav.label.home"}/>
        </h1>
    </>

}


export default Home


