import React, {ChangeEventHandler, forwardRef} from "react";
import {Form} from "react-bootstrap";

interface Props {
    onChange: ChangeEventHandler<HTMLSelectElement>
    labelId: string
    label: string
    value?: string|number
    name?:string
    className: string
    errorText?: string,
    selectId: string
    data: React.ReactNode
}

const MySelect = forwardRef(({name="",onChange,  value,label, className, data,errorText}: Props,ref:any) => {

        const capitalizedLabel = label.split(" ").filter(word=>word.length>0).map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ")

        return (
            <>
                <Form.Label>{capitalizedLabel}</Form.Label>
                {!value && <Form.Select ref={ref} name={name} className={className} onChange={onChange} aria-label="Default select example">
                    {data}
                </Form.Select>}
                {value && <Form.Select value={value} ref={ref} name={name} className={className} onChange={onChange} aria-label="Default select example">
                    {data}
                </Form.Select>}
                {errorText && <p className={"text-danger"}>{errorText}</p>}
            </>)
    }
)

export default MySelect