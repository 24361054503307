import MyModal from "../../../widgets/MyModal";
import MyButton from "../../../widgets/MyButton";
import {Colors} from "../../../widgets/Colors";


interface Props {
    show: boolean,
    setShow: (show: boolean) => void,
    watchedInfo?:JSX.Element[],
    offendingInfo?:JSX.Element[],
}

const ClassesModal = ({show, setShow,watchedInfo,offendingInfo}: Props) => {


    if(!watchedInfo && !offendingInfo) return <></>
    const handleClose = () => {
        setShow(false)
    }

    const formBody = () => {
        return <>
            <div key={0}>
                <>`
                    <label>Vigiada</label>
                    {watchedInfo}
                </>
            </div>
            <div key={1}>
                <>
                    <label>Ofensora</label>
                    {offendingInfo}
                </>
            </div>
        </>
    }


    const formFooter = () => {
        return <>
        <MyButton label={"fechar"} onClick={handleClose} color={Colors.GRAY}/>
        </>

    }

    return <>
        <MyModal body={formBody()} footer={formFooter()} title={`Detalhes das Classes`} show={show} size={"lg"}
                 handleClose={handleClose}/>
    </>
}

export default ClassesModal