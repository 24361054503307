import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Representative} from "../../models/Representative";


interface RepresentativesState {
    representatives: Representative[],
}

const representativesState: RepresentativesState = {
    representatives: []
}

const representativeSlice = createSlice({
    name: "representatives",
    initialState: representativesState,
    reducers: {
        setRepresentatives: (state, action: PayloadAction<Representative[]>) => {
            state.representatives = action.payload
        },
        clearState: () => representativesState

    }
})


export default representativeSlice.reducer
export const clearRepresentativeState = representativeSlice.actions.clearState
export const setRepresentatives = representativeSlice.actions.setRepresentatives
