import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {WatchTask} from "../../models/WatchTask";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "../hooks";
import {PublicationInfo} from "../../models/PublicationInfo";

interface WatchTaskState {
    watchTaskList: WatchTask[],
    publicationsInfo: PublicationInfo[],
}

const watchTaskState: WatchTaskState = {
    watchTaskList: [],
    publicationsInfo: [],
}

const watchTaskSlice = createSlice(
    {
        name: "watchTask",
        initialState: watchTaskState,
        reducers: {
            clearState: () => watchTaskState,
            setWatchTaskList: (state, action: PayloadAction<WatchTask[]>) => {
                state.watchTaskList = action.payload
            },
            updateWatchTaskList: (state, action: PayloadAction<WatchTask[]>) => {
                const newTasks = action.payload
                if (newTasks.length === 0) return
                newTasks.filter(task => {
                    const taskDay = dayjs(task.dateWatch).day()
                    return taskDay !== 0 && taskDay !== 6
                }).forEach(task => {
                    state.watchTaskList.push(task)
                })
            },

            addNewClosedWatchTask: (state, action: PayloadAction<WatchTask[]>) => {
                action.payload.forEach(watchTask => {
                        const index = state.watchTaskList.findIndex(t => t.dateWatch === watchTask.dateWatch && !t.closeUser)
                        state.watchTaskList[index] = watchTask
                    }
                )
            },

            setPublicationsInfo: (state, action: PayloadAction<PublicationInfo[]>) => {
                const newSummaries = action.payload
                if (newSummaries.length === 0) return
                state.publicationsInfo = newSummaries
            }
        }
    }
)

export default watchTaskSlice.reducer
export const clearWatchTaskState = watchTaskSlice.actions.clearState
export const setWatchTaskList = watchTaskSlice.actions.setWatchTaskList

export const useWatchTaskActions = () => {

    const {
        watchTaskList,
        publicationsInfo,
    } = useAppSelector(state => state.watchTask)
    const {
        updateWatchTaskList,
        setWatchTaskList,
        addNewClosedWatchTask,
        setPublicationsInfo
    } = watchTaskSlice.actions
    const dispatch = useAppDispatch()


    return {
        watchTaskList,
        publicationsInfo,
        updateWatchTaskList: (tasks: WatchTask[]) => {
            dispatch(updateWatchTaskList(tasks))
        },
        setWatchTaskList: (tasks: WatchTask[]) => {
            dispatch(setWatchTaskList(tasks))
        },
        addNewClosedWatchTask: (tasks: WatchTask[]) => {
            dispatch(addNewClosedWatchTask(tasks))
        },
        setPublicationsInfo: (summaries: PublicationInfo[]) => {
            dispatch(setPublicationsInfo(summaries))
        }
    }
}