import React, {useEffect} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {IgnoredWord} from "../../models/IgnoredWord";
import MyTextInput from "../../widgets/MyTextInput";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import {array, object, string} from "yup";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUserThunks} from "../../store/thunks/UserThunks";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";

const IgnoredWordSchema = object<IgnoredWord[]>().shape({
    ignoredWords: array().of(
        object().shape({
            word: string().required()
        })
    )
}).required()


const ManageIgnoredWords = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        getValues,
        setValue
    } = useForm({
        resolver: yupResolver(IgnoredWordSchema)
    });

    const {isLoading} = useIsLoadingActions()

    const {
        append,
        remove,
    } = useFieldArray({
        control,
        name: "ignoredWords"
    });

    const intl = useIntl()
    const messages = {
        wordsToBeIgnored: intl.formatMessage({id: "profile.label.wordsToBeIgnored"}),
        word: intl.formatMessage({id: "profile.label.word"}),
        newWord: intl.formatMessage({id: "profile.label.newWord"}),
        remove: intl.formatMessage({id: "generics.label.remove"}),
        saveChanges: intl.formatMessage({id: "generics.label.saveChanges"}),
    }

    const {getIgnoredWords, updateIgnoredWords} = useUserThunks()

    const updateYup = (words: IgnoredWord[]) => {
        setValue("ignoredWords", words)
    }

    useEffect(() => {

        getIgnoredWords(result => {
            updateYup(result)
        })
    }, [])

    const handleAddNewWord = () => {
        if (getValues("ignoredWords")?.filter(iw => iw.word === "").length === 0) {
            append({word: ""})
        }
    }
    const handleUpdateIgnoredWords = async (ignoredWords:IgnoredWord[] | undefined) => {
        if(ignoredWords){
            updateIgnoredWords(ignoredWords)
        }
    }

    return <>
        <Container className={"mt-3"} style={{border: "1px", borderStyle: "solid"}}>
            <div className={"mb-2"}>{messages.wordsToBeIgnored}</div>
            {
                getValues("ignoredWords")?.map(
                    (_, index) => <Row key={index} className={"mb-2"}>

                        <Col>

                            <MyTextInput label={messages.word}
                                         {...register(`ignoredWords.${index}.word`)}
                                         errorText={
                                             (
                                                 errors.ignoredWords && errors.ignoredWords.length &&
                                                 errors.ignoredWords.length >= index
                                             )
                                                 ? errors.ignoredWords[index]?.word?.message : ""}

                            />
                        </Col>
                        <Col>

                            <Form.Label className={"invisible"}>1</Form.Label>
                            <div>
                                <MyButton label={messages.remove} disabled={isLoading}
                                          onClick={() => remove(index)}
                                          color={Colors.RED}/>
                            </div>
                        </Col>
                    </Row>
                )
            }

            <Row lg={"6"}>
                <MyButton className={"mt-3"} disabled={isLoading} label={messages.newWord} onClick={handleAddNewWord}/>
            </Row>
            <Row lg={"6"}>
                <MyButton className={"mt-5"} label={messages.saveChanges} disabled={isLoading}
                          onClick={handleSubmit((data) => handleUpdateIgnoredWords(data.ignoredWords))}
                          color={Colors.GREEN}/>
            </Row>

            {isLoading && <Spinner animation={"border"}/>}

        </Container>
    </>
}
export default ManageIgnoredWords