import {useKeycloak} from "@react-keycloak/web";

interface Props {
    children: JSX.Element,
    isAdmin?:boolean,
    isManager?:boolean,
}

const RequireAuth = ({children,isAdmin=false, isManager=false}: Props) => {

    const {keycloak} = useKeycloak();
    if(isAdmin){
        return keycloak.hasRealmRole("admin") ? children : null;
    }else if(isManager){
        return keycloak.hasRealmRole("manager") ? children : null;
    }else{
        return keycloak.authenticated ? children : null;
    }

    // return keycloak.hasRealmRole("admin") ? children : null;

}

export default RequireAuth;