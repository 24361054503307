import {Container} from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import TitularStatistics from "./TitularStatistics";
import RepresentativeStatistics from "./RepresentativeStatistics";
import {AccordionItems} from "../../models/AccordionItems";
import {useIntl} from "react-intl";

const Statistics = () => {


    const intl=useIntl()
    const messages={
        listTitularWithoutRepresentatives:intl.formatMessage({id:"bulletins.label.listTitularWithoutRepresentatives"}),
        listRepresentativeWithMostRegisters:intl.formatMessage({id:"bulletins.label.listRepresentativeWithMostRegisters"}),
    }
    const items = (): AccordionItems[] => {
        return [
            {header: messages.listTitularWithoutRepresentatives, body: <TitularStatistics/>},
            {header: messages.listRepresentativeWithMostRegisters, body: <RepresentativeStatistics/>},
        ]
    }

    return <>

        <Container>
            <MyAccordion items={items()}/>
        </Container>

    </>
}


export default Statistics