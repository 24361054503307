import axios, {AxiosInstance, AxiosResponse, AxiosResponseHeaders} from "axios";
import {toast} from "react-toastify";

import keycloak from "../keycloak/Keycloak";


export const BaseAPI = (url?: string) => {

    const controller = new AbortController();

    const createAxios = (url?: string): AxiosInstance => {
        const axiosObj = axios.create()
        axiosObj.defaults.timeout = 3 * 60 * 1000
        axiosObj.defaults.baseURL = url;
        return axiosObj
    }

    const axiosObj = createAxios(url)

    axiosObj.interceptors.request.use(config => {
        const token = keycloak.token
        if (token) {
            const headers = config.headers
            if (headers) {
                headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    })

    axiosObj.interceptors.response.use(async response => response, (error) => {
        if (error) {
            // toast.error(error.message)
            const errorData: string[] = error?.response?.data?.causes

            if (errorData?.length > 0) {

                errorData?.forEach(error => {
                        // toast.error(error)
                    }
                )

            } else {
                if (error?.response?.status === 403) {
                    toast.error("Erro na autenticação. Favor fazer login")
                } else if (error?.response?.status === 401) {
                    toast.error("Keycloak error")
                }
            }
        } else {
            toast.error("Something weird??")
        }

        return error

    })

    const responseBody = <T>(response: AxiosResponse<T>) => response.data;


    const getFilename = (headers: AxiosResponseHeaders) => {
        const contentHeaders = (headers["content-disposition"].split(";")) as string[]
        return contentHeaders.filter(h => h.includes("filename"))[0]
            .split("=")[1]
            .replaceAll('"', "")
    }

    const downloadResource = (url: string, filename: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const downloadBody = (response: AxiosResponse<string>) => {
        const {data, headers} = response
        const filename = getFilename(headers)
        const url = window.URL.createObjectURL(new Blob([data]));
        downloadResource(url, filename)
        return response
    }


    return {
        get: <T>(url: string) => axiosObj.get<T>(url, {signal: controller.signal}).then(responseBody),
        post: <T, Y>(url: string, body: Y) => axiosObj.post<T>(url, body, {signal: controller.signal}).then(responseBody),
        patch: <T, Y>(url: string, body: Y) => axiosObj.patch<T>(url, body, {signal: controller.signal}).then(responseBody),
        put: <T, Y>(url: string, body: Y) => axiosObj.put<T>(url, body, {signal: controller.signal}).then(responseBody),
        delete: <T>(url: string) => axiosObj.delete<T>(url, {signal: controller.signal}).then(responseBody),
        postFormData: <T, Y>(url: string, body: Y) => axiosObj.post<T>(url, body, {
            signal: controller.signal,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(responseBody),

        headers: <T, Y>(url: string, body: Y) => axiosObj.post<T>(url, body).then(response => {
            return response.headers
        }),

        download: (url: string) => axiosObj.get(url, {responseType: "blob"}).then(downloadBody),

        downloadPost: <Y>(url: string, body: Y) => axiosObj.post(url, body, {responseType: "blob"}).then(downloadBody),
    }

}


