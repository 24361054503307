import MyModal from "./MyModal";
import {RegisterClass} from "../models/RegisterClass";
import MyButton from "./MyButton";
import {Colors} from "./Colors";

interface Props {
    show: boolean,
    setShow: (show:boolean) => void,
    classes: RegisterClass[]
}

const RegisterClassModal = ({show, setShow, classes}: Props) => {

    const handleClose = () => {
        setShow(false)
    }

    const formBody = () => {
        return <>
            {
                classes?.map((rc, index) => {
                    return <p key={index}>{rc.key}: {rc.value}</p>
                })
            }
        </>
    }


    const formFooter = () => {
        return <>
            <MyButton label={"fechar"} onClick={handleClose} color={Colors.GRAY} />
        </>

    }

    return <>
        <MyModal body={formBody()} footer={formFooter()} title={`Detalhes do Registo`} show={show} size={"sm"}
         handleClose={handleClose}/>
    </>
}

export default RegisterClassModal