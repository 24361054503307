import {Container, Nav, Navbar, NavDropdown, Spinner} from "react-bootstrap";
import {Route, Routes} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import Home from "../features/Home";
import FreeSearch from "../features/similarities/FreeSearch";
import Customers from "../features/customers/Customers";
import RequireAuth from "./RequireAuth";
import Bulletins from "../features/bulletins/Bulletins";
import BrandWithoutRepresentatives from "../features/brandsWithoutRepresentative/BrandWithoutRepresentatives";
import Representatives from "../features/representatives/Representatives";
import User from "../features/user/User";
import Incidents from "../features/incidents/Incidents";
import SearchIncidents from "../features/incidents/SearchIncidents";
import Statistics from "../features/statistics/Statistics";
import Renewals from "../features/renewals/Renewals";
import {useUserActions} from "../store/slices/UserSlice";
import React, {useEffect} from "react";
import {useAuthentication} from "../store/thunks/AuthenticationThunks";
import ForceCheck from "../features/forceCheck/ForceCheck";
import {useKeycloak} from "@react-keycloak/web";
import KeycloakView from "../features/keycloak";
import MyButton from "./MyButton";
import {Colors} from "./Colors";
import {Language, languages} from "../i18n/languages";
import {useIntl} from "react-intl";
import en from "../uk_flag.png"
import pt from "../portuguese_flag.png"
import Similarities from "../features/similarities/Similarities";
import {useIsLoadingActions} from "../store/slices/IsLoadingSlice";

interface Props {
    setLanguage: (language: Language) => void
}


const MyNav = ({setLanguage}: Props) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { doPostLogin, doLogout} = useAuthentication()
    const {isLoading}=useIsLoadingActions()

    const messages = {
        home: intl.formatMessage({id: "nav.label.home"}),
        logout: intl.formatMessage({id: "nav.label.logout"}),
        customers: intl.formatMessage({id: "nav.label.customers"}),
        incidents: intl.formatMessage({id: "nav.label.incidents"}),
        similarities: intl.formatMessage({id: "nav.label.similarities"}),
        profile: intl.formatMessage({id: "nav.label.profile"}),
        team: intl.formatMessage({id: "nav.label.team"}),
        keycloak: intl.formatMessage({id: "nav.label.keycloak"}),
        forceScheduler: intl.formatMessage({id: "nav.label.forceScheduler"}),
        freeSearch: intl.formatMessage({id: "nav.label.freeSearch"}),
        searchIncidents: intl.formatMessage({id: "nav.label.searchIncidents"}),
        renewals: intl.formatMessage({id: "nav.label.renewals"}),
        bulletins: intl.formatMessage({id: "nav.label.bulletins"}),
        registersWithoutRepresentatives: intl.formatMessage({id: "nav.label.registersWithoutRepresentatives"}),
        listRepresentatives: intl.formatMessage({id: "nav.label.listRepresentatives"}),
        statistics: intl.formatMessage({id: "nav.label.statistics"}),
    }

    const {isLoggedIn, userIsAdmin, userIsManager, name} = useUserActions()


    const {keycloak} = useKeycloak()

    useEffect(() => {
        if (keycloak.authenticated) {
            doPostLogin(keycloak)
        }
    }, [keycloak.token])

    const handleLogin = async () => {
        keycloak.login()
    }

    const handleLogout = async () => {
        keycloak.logout()
        doLogout()
        navigate("/")
    }

    const languageButtons = () => {
        const dimensions = 60
        return languages.map(l => {
            return <img alt={"flag"} key={l.name} onClick={() => setLanguage(l)} src={l.name === "Português" ? pt : en}
                        width={dimensions} height={dimensions}/>
        })
    }

    const renderLinks = () => {
        return <>
            <Nav variant={"tabs"}>
                <Nav.Link className={"ms-sm-1"} as={Link} to={"/"}>
                    <p>{messages.home}</p>
                </Nav.Link>
                {
                    loggedLinks()
                }
                {
                    adminLinks()
                }
                <Nav className={"mx-2"}>
                    {
                        !isLoggedIn && <>
                            <MyButton label={"login"} onClick={handleLogin} color={Colors.BLUE}/>
                        </>
                    }
                    {
                        isLoggedIn && <>
                            <Navbar.Text>Signed in as: {name}</Navbar.Text>
                            <MyButton className={"mx-2"} label={messages.logout} onClick={handleLogout}
                                      color={Colors.BLUE}/>
                            {languageButtons()}


                        </>
                    }
                </Nav>

            </Nav>

        </>
    }

    const loggedLinks = () => {
        return isLoggedIn && <>
            <Nav.Link as={Link} to={"/clientes"}>
                {messages.customers}
            </Nav.Link>
            <Nav.Link as={Link} to={"/incidents"}>
                {messages.incidents}
            </Nav.Link>
            <Nav.Link as={Link} to={"/similaridades"}>
                {messages.similarities}
            </Nav.Link>
            <Nav.Link as={Link} to={"/user"}>
                {messages.profile}
            </Nav.Link>
        </>
    }

    const adminLinks = () => {
        return (userIsAdmin || userIsManager) && <>
            <NavDropdown title={"Admin"} id={"basic-nav-dropdow"}>
                {
                    userIsAdmin && <>
                        <NavDropdown.Item as={Link} to={"/keycloak"}>
                            {messages.keycloak}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/force-check"}>
                            {messages.forceScheduler}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/pesquisalivre"}>
                            {messages.freeSearch}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/search-incidents"}>
                            {messages.searchIncidents}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/renewals"}>
                            {messages.renewals}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/boletins"}>
                            {messages.bulletins}
                        </NavDropdown.Item>
                    </>
                }
                <NavDropdown.Item as={Link} to={"/marcas-sem-representantes"}>
                    {messages.registersWithoutRepresentatives}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/representantes"}>
                    {messages.listRepresentatives}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/statistics"}>
                    {messages.statistics}
                </NavDropdown.Item>
            </NavDropdown>
        </>
    }
    const renderRoutes = () => {
        return <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/force-check"} element={
                <RequireAuth isAdmin={true}>
                    <ForceCheck/>
                </RequireAuth>
            }/>
            <Route path={"/keycloak"} element={
                <RequireAuth isAdmin={true}>
                    <KeycloakView/>
                </RequireAuth>
            }/>
            <Route path={"/incidents"} element={
                <RequireAuth>
                    <Incidents/>
                </RequireAuth>
            }/>
            <Route path={"/renewals"} element={
                <RequireAuth>
                    <Renewals/>
                </RequireAuth>
            }/>
            <Route path={"/search-incidents"} element={
                <RequireAuth isAdmin={true}>
                    <SearchIncidents/>
                </RequireAuth>
            }/>
            <Route path={"/clientes"} element={
                <RequireAuth>
                    <Customers/>
                </RequireAuth>
            }/>
            <Route path={"/similaridades"} element={
                <RequireAuth>
                    <Similarities/>
                </RequireAuth>
            }/>
            <Route path={"/pesquisalivre"} element={
                <RequireAuth isAdmin={true}>
                    <FreeSearch/>
                </RequireAuth>
            }/>
            <Route path={"/boletins"} element={
                <RequireAuth isAdmin={true}>
                    <Bulletins/>
                </RequireAuth>
            }/>
            <Route path={"/marcas-sem-representantes"} element={
                <RequireAuth isManager={true}>
                    <BrandWithoutRepresentatives/>
                </RequireAuth>
            }/>
            <Route path={"/representantes"} element={
                <RequireAuth isManager={true}>
                    <Representatives/>
                </RequireAuth>
            }/>
            <Route path={"/user"} element={
                <RequireAuth>
                    <User/>
                </RequireAuth>
            }/>
            <Route path={"/statistics"} element={
                <RequireAuth isManager={true}>
                    <Statistics/>
                </RequireAuth>
            }/>
            <Route path={"*"} element={<Home/>}/>
        </Routes>
    }

    return <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse role={"navigation"} id="responsive-navbar-nav">{renderLinks()}</Navbar.Collapse>
            </Container>
        </Navbar>
        {renderRoutes()}
        {/*{!isLoading && renderRoutes()}*/}
        {/*{isLoading && <Container className={"m-5"}>*/}
        {/*        <Spinner animation={"border"}/>*/}
        {/*    </Container>*/}
        {/*}*/}
    </>


}

export default MyNav