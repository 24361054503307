import { Table} from "react-bootstrap";
import {useState} from "react";
import RegisterClassModal from "./RegisterClassModal";
import {BrandRegister} from "../models/BrandRegister";
import MyButton from "./MyButton";
import {useIntl} from "react-intl";
import {useBulletinsActions} from "../store/slices/BulletinsSlice";


const RegisterTable = () => {

    const {registerPage}=useBulletinsActions()
    const registers=registerPage.content

    const [showModal, setShowModal] = useState(false)
    const [modalRegister, setModalRegister] = useState<BrandRegister>({} as BrandRegister)

    const intl=useIntl()
    const messages={
        number:intl.formatMessage({id:"model.number"}),
        brandText:intl.formatMessage({id:"model.brandText"}),
        image:intl.formatMessage({id:"model.image"}),
        classes:intl.formatMessage({id:"customers.label.classes"}),
        details:intl.formatMessage({id:"customers.label.details"}),
        titular:intl.formatMessage({id:"model.titular"}),
        address:intl.formatMessage({id:"model.address"}),
        representative:intl.formatMessage({id:"model.representative"}),

    }

    const handleShowModal = (register:BrandRegister) => {
        setShowModal(true)
        setModalRegister(register)
    }

    return <>
        {registers?.length > 0 && <Table striped bordered hover>
            <thead>
            <tr>
                <th>{messages.number}</th>
                <th>{messages.brandText}</th>
                <th>{messages.image}</th>
                <th>{messages.classes}</th>
                <th>{messages.details}</th>
                <th>{messages.titular}</th>
                <th>{messages.address}</th>
                <th>{messages.representative}</th>
                <th>{messages.address}</th>
            </tr>
            </thead>

            <tbody>
            {
                registers.map(register => {
                    return <tr key={register.number}>
                        <td>{register.number}</td>
                        <td>{register.brandText}</td>
                        <td> {register.image &&
                            <img src={`data:image/png;base64,${register?.image}`} alt={""}/>
                        } </td>
                        <td> {register.registerClasses.map((rc, index) => `${rc.key}${index < register.registerClasses.length - 1 ? '; ' : ''}`)} </td>
                        <td>
                            <MyButton label={messages.details} onClick={()=>handleShowModal(register)} />
                        </td>
                        <td>{register.titularName}</td>
                        <td>{register.titularAddress}</td>
                        <td>{register.representative}</td>
                        <td>{register.representativeAddress}</td>
                    </tr>
                })
            }

            </tbody>

        </Table>


        }
        <RegisterClassModal show={showModal} setShow={setShowModal} classes={modalRegister?.registerClasses}/>

    </>
}


export default RegisterTable