import {Col, Form, Image, Row, Spinner} from "react-bootstrap";
import React, {ChangeEvent, useEffect,} from "react";
import {BrandRegister} from "../../models/BrandRegister";
import {useCustomerActions} from "../../store/slices/CustomerSlice";
import MyTextInput from "../../widgets/MyTextInput";
import {useKeycloak} from "@react-keycloak/web";
import {mapInstitute} from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import {array, boolean, number, object, string} from 'yup';
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import MyModal from "../../widgets/MyModal";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useBrandRegisterThunks} from "../../store/thunks/BrandRegisterThunks";

interface Action {
    action: (arg: any) => void
    label: string,
    color: Colors
    isSubmit: boolean
}

interface Props {
    handleRegister: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void,
    onSelectChange: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void,
    isUpdate: boolean,

    show: boolean,
    size: "sm" | "lg" | "xl",
    title: string,
    actions: Action[]
}

const RegisterSchema = object<BrandRegister>().shape({
    id: number().optional(),
    number: string().required(),
    source: string().required(),
    brandText: string().required(),
    reference: string().optional(),
    alias: string().optional(),
    image: string().optional(),
    dateRequest: string().required(),
    datePublication: string().optional(),
    concessionEndDate: string().optional(),
    bypass: boolean().required().default(false),
    registerClasses: array().min(1).of(
        object().shape({
            key: string().required(),
            value: string().optional(),
        })
    ).required()
}).required()

const RegisterForm = (
    {
        show, size, title, actions,
        isUpdate = false, onSelectChange = () => {
    },
    }: Props) => {

    const updateYup = (register: BrandRegister) => {
        setValue("id", register.id)
        setValue("number", register.number)
        setValue("source", register.source ?? "PT")
        setValue("brandText", register.brandText)
        setValue("reference", register.reference ?? "")
        setValue("alias", register.alias)
        setValue("image", register.image ?? "")
        setValue("dateRequest", register.dateRequest)
        setValue("datePublication", register.dateRequest)
        setValue("concessionEndDate", register.concessionEndDate)
        setValue("bypass", register.bypass)
        setValue("registerClasses", register.registerClasses)
    }

    const {
        register: formHookRegister,
        handleSubmit,
        formState: {errors},
        watch,
        control,
        getValues,
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(RegisterSchema)
    });


    const {
        append,
        remove,
    } = useFieldArray({
        control,
        name: "registerClasses"
    });


    const intl = useIntl()
    const messages = {
        registers: intl.formatMessage({id: "customers.label.registers"}),
        number: intl.formatMessage({id: "model.number"}),
        institute: intl.formatMessage({id: "model.institute"}),
        fetchData: intl.formatMessage({id: "customers.label.fetchData"}),
        brandText: intl.formatMessage({id: "model.brandText"}),
        reference: intl.formatMessage({id: "model.reference"}),
        alias: intl.formatMessage({id: "model.alias"}),
        image: intl.formatMessage({id: "model.image"}),
        requestDate: intl.formatMessage({id: "model.requestDate"}),
        endDate: intl.formatMessage({id: "model.concessionEndDate"}),
        ignoreWords: intl.formatMessage({id: "customers.label.ignoreWords"}),
        classes: intl.formatMessage({id: "customers.label.classes"}),
        moreClasses: intl.formatMessage({id: "customers.label.moreClasses"}),
        key: intl.formatMessage({id: "model.key"}),
        value: intl.formatMessage({id: "model.value"}),
        noRegisters: intl.formatMessage({id: "customers.label.noRegisters"}),
    }
    const {selectedCustomer, selectedRegister} = useCustomerActions()
    const {getRegisterDataFromAPI} = useBrandRegisterThunks()
    const {keycloak} = useKeycloak()
    const {setIsLoadingModal, isLoadingModal} = useIsLoadingActions()
    const registers = selectedCustomer.brandRegisters
    const currentSource = watch("source")
    const image = getValues("image")

    useEffect(() => {
        if (selectedRegister) {
            updateYup(selectedRegister)
        } else {
            setValue("source", "PT")
        }
        return () => {
            reset()
        }
    }, [selectedRegister])


    const handleAddNewClass = () => {
        if (getValues("registerClasses")?.filter(rc => rc.key === "").length === 0) {
            append({key: "", value: ""})
        }
    }

    const handleRemoveClass = (index: number) => {
        remove(index)
    }

    const handleFetchDataFromApi = async () => {
        if (selectedCustomer.id) {
            setIsLoadingModal(true)

            const number = getValues("number")
            const source = getValues("source")

            reset()
            const handleResult = (registerFetchedFromAPI: BrandRegister) => {
                setIsLoadingModal(false)

                if (registerFetchedFromAPI) {
                    updateYup(registerFetchedFromAPI)
                }
            }

            getRegisterDataFromAPI(number, source, selectedCustomer.id, handleResult)
        }

    }

    const getRegisters = () => {
        if (registers.length === 0) {
            return [<option key={0}>{messages.noRegisters}</option>]
        }

        return [registers?.map(register =>
            <option
                value={register.id ?? ''} key={register.number}>{register.brandText}</option>)]
    }
    const isValidButton = (): boolean => {
        return !isUpdate || (isUpdate && selectedRegister !== undefined && selectedRegister.number !== undefined && selectedRegister.number.length > 0)
    }

    const formFooter = () => {
        return <>
            {
                actions.map(({action, label, color, isSubmit}, index) => {
                    if (isSubmit) {
                        return <MyButton disabled={!isValidButton()} key={index} label={label}
                                         onClick={handleSubmit(action)} color={color}/>
                    } else {
                        return <MyButton key={index} label={label} onClick={action} color={color}/>
                    }

                })
            }
        </>
    }

    const showFetchButton = () => currentSource && (currentSource === "PT" || currentSource === "EU")


    const renderClass = (index: number) => {
        return <Row key={index}>
            <Col>
                <MyTextInput type={"number"} inputClassName={"mb-2"}
                             label={messages.key}{...formHookRegister(`registerClasses.${index}.key`)}
                             errorText={errors.registerClasses?.message ?? ""}
                />

            </Col>
            <Col>
                <MyTextInput inputClassName={"mb-2"}
                             label={messages.value}{...formHookRegister(`registerClasses.${index}.value`)}
                             errorText={errors.registerClasses?.message ?? ""}
                />
            </Col>
            <Col>
                <Form.Label className={"mb-2 invisible"}> 1</Form.Label>
                <div>
                    <MyButton label={"remove"} onClick={() => handleRemoveClass(index)}/>

                </div>

            </Col>
        </Row>
    }

    const formBody = () => {
        return <Form>
            <Form.Group>

                {
                    isUpdate && <>
                        <MySelect labelId={""} label={messages.registers}
                                  onChange={onSelectChange} value={selectedRegister?.id}
                                  className={"mb-2"} selectId={""} data={getRegisters()}
                        />
                    </>
                }
                <Row className={"mb-1"}>
                    <Col>
                        <MyTextInput inputClassName={"mb-2"} label={messages.number}{...formHookRegister("number")}
                                     errorText={errors.number?.message}/>
                    </Col>
                    <Col>
                        <MySelect labelId={""} label={messages.institute} className={"mb-2"} selectId={""}
                                  {...formHookRegister("source")} errorText={errors.source?.message} data={
                            keycloak.tokenParsed?.institutes?.map((inst: string) => {
                                const institute = mapInstitute(inst)
                                if (institute) {
                                    return <option key={institute.origin}
                                                   value={institute.origin}>{institute.name}</option>
                                }
                            })
                        }/>
                    </Col>
                </Row>
                {
                    showFetchButton() && <Row className={"mb-1"}>
                        <Col>
                            <MyButton label={messages.fetchData} onClick={handleFetchDataFromApi}/>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <MyTextInput inputClassName={"mb-2"}
                                     errorText={errors.brandText?.message}
                                     label={messages.brandText}{...formHookRegister("brandText")}/>
                    </Col>
                    <Col>
                        <MyTextInput inputClassName={"mb-2"}
                                     errorText={errors.reference?.message}
                                     label={messages.reference}{...formHookRegister("reference")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MyTextInput inputClassName={"mb-1"}
                                     errorText={errors.alias?.message}
                                     label={messages.alias}{...formHookRegister("alias")}/>
                    </Col>
                    <Col></Col>
                </Row>

                {
                    image && <Row>
                        <Form.Label>{messages.image}</Form.Label>
                        <Col>
                            <Image src={image ? `data:image/png;base64,${image}` : ''}
                                   height={200} width={200}/>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <MyTextInput type={"date"} inputClassName={"mb-2"}
                                     label={messages.requestDate}{...formHookRegister("dateRequest")}
                                     errorText={errors.dateRequest?.message}
                        />
                    </Col>
                    <Col>
                        <MyTextInput type={"date"} inputClassName={"mb-2"}
                                     label={messages.endDate}{...formHookRegister("concessionEndDate")}
                                     errorText={errors.concessionEndDate?.message}
                        />
                    </Col>
                </Row>

                <Row className={"mt-2"}>
                    <Col>
                        <Form.Check label={messages.ignoreWords}
                                    {...formHookRegister("bypass")}/>
                        {errors.bypass?.message && <p className={"text-danger"}>{errors.bypass?.message}</p>}
                    </Col>

                </Row>
                <Row className={"mt-2"}>
                    <Form.Label>{messages.classes}</Form.Label>

                </Row>
                {
                    getValues("registerClasses")?.map((registerClass, index) => renderClass(index))
                }
                {errors.registerClasses?.message && <p className={"text-danger"}>{errors.registerClasses?.message}</p>}
                <MyButton label={messages.moreClasses} onClick={handleAddNewClass} color={Colors.GREEN}/>
            </Form.Group>
            {isLoadingModal && <Spinner animation={"border"}/>}
        </Form>
    }

    return <>
        <MyModal show={show} size={size ?? "lg"} title={title} body={formBody()} footer={formFooter()}
                 handleClose={() => {
                 }}/>

    </>
}

export default RegisterForm;