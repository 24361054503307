import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MyNav from "./widgets/MyNav";
import {IntlProvider} from "react-intl";
import {messages} from "./i18n/messages";
import { useState} from "react";
import {languages} from "./i18n/languages";

function App() {

    const [language, setLanguage] = useState(languages[0])



    return (
        <>

            <BrowserRouter>
                <IntlProvider locale={language.code} defaultLocale={language.code} messages={messages[language.code]}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <MyNav setLanguage={setLanguage} />
                </IntlProvider>

            </BrowserRouter>

        </>
    );
}

export default App;
