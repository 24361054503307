import {Col, Form, Row} from "react-bootstrap";
import React from "react";
import {useIntl} from "react-intl";
import dayjs, {Dayjs} from "dayjs";
import MyDatePicker from "../../widgets/MyDatePicker";
import {useSimilarityThunks} from "../../store/thunks/SimilarityThunks";
import {friendlyDate} from "../../utils/DateUtils";
import {useWatchTaskActions} from "../../store/slices/WatchTaskSlice";
import {useSimilarityActions} from "../../store/slices/SimilaritySlice";


interface Props {
    date: Dayjs,
    setDate: (date: Dayjs) => void,
    label: string
}

const DailyWatchDetails = ({date, setDate, label}: Props) => {
    const intl = useIntl()
    const messages = {
        publications: intl.formatMessage({id: "similarities.label.publications"}),
        on: intl.formatMessage({id: "generics.label.on"}),
        watchStartedBy: intl.formatMessage({id: "similarities.label.watchStartedBy"}),
        watchEndedBy: intl.formatMessage({id: "similarities.label.watchEndedBy"}),
    }

    const {getRegistersSummary,} = useSimilarityThunks()
    const {watchTaskList} = useWatchTaskActions()
    const {origin} = useSimilarityActions()
    const brandRegisterSummary = getRegistersSummary(date)

    const tasksByDateAndOrigin = (date: dayjs.Dayjs) => {
        return watchTaskList?.filter(task => task.dateWatch === friendlyDate(date) && task.origin === origin) ?? []
    }

    const tasks = tasksByDateAndOrigin(date)


    if (!tasks && !brandRegisterSummary) return <></>
    return <>
        <Form.Label>{label}</Form.Label>
        <MyDatePicker
            date={dayjs(date)} onChangeDate={(day: Dayjs) => setDate(day)}
        />
        {tasks?.map(({openUser, openDate, closeUser, closeDate}, index) => {

            const openTaskRow = <Col> {messages.watchStartedBy} <i>{openUser}</i> {messages.on} {openDate}</Col>
            const closeTaskRow = <Col>{messages.watchEndedBy} <i>{closeUser}</i> {messages.on} {closeDate}</Col>
            return <React.Fragment key={index}>
                <Row className={"mt-2"}>
                    {!(closeDate && closeUser) ? <b>{openTaskRow}</b> : <>{openTaskRow}</>}
                </Row>
                <Row>
                    {closeDate && closeUser &&
                        <>{closeTaskRow}</>
                    }
                </Row>

            </React.Fragment>
        })}
        <Row>
            <Col>{messages.publications}: {brandRegisterSummary.count ? brandRegisterSummary.count : 0}</Col>
        </Row>
    </>
}

export default DailyWatchDetails