import {requests} from "./API";
import {Incident} from "../../models/Incident";
import {IncidentWatchTask} from "../../models/IncidentWatchTask";

const baseUrl = "/incidents"
const IncidentRequests = {
    getIncidents: (startDay: string, source: string) => requests.get<Incident[]>(`${baseUrl}/${startDay}/${source}`),
    getIncidentsByNumberAndDay: (number: string, startDay: string, endDay: string) => requests.get<Incident[]>(`${baseUrl}/number/${number}/${startDay}/${endDay}`),
    getWatchTaskList: (startDate: string, origin: string) => requests.get<IncidentWatchTask[]>(`${baseUrl}/task/${startDate}/${origin}`),
    getMonthSummary: (date: string, origin: string) => requests.get<IncidentWatchTask[]>(`${baseUrl}/task/month/${date}/${origin}`),
}

export default IncidentRequests