import {IncidentWatchTask} from "../../models/IncidentWatchTask";
import {useIntl} from "react-intl";
import {Col, Row} from "react-bootstrap";
import React from "react";

interface Props {
    tasks: IncidentWatchTask[],
}

const IncidentDailyWatchDetails = ({tasks,}: Props) => {

    const intl = useIntl()
    const messages = {
        bulletinOpenedBy: intl.formatMessage({id: "incidents.label.bulletinOpenedBy"}),
        on: intl.formatMessage({id: "generics.label.on"}),
    }
    if (tasks.length === 0) return <></>
    return <>
        {

            tasks.map(({openUser, openDate,}, index) => {

                const openTaskRow = <Col><> {messages.bulletinOpenedBy} <i>{openUser}</i> {messages.on} {openDate}</>
                </Col>
                return <React.Fragment key={index}>
                    <Row className={"mt-2"}>
                        {openTaskRow}
                    </Row>


                </React.Fragment>
            })
        }
    </>
}

export default IncidentDailyWatchDetails