import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Team} from "../../models/Team";
import {useAppDispatch, useAppSelector} from "../hooks";

interface TeamState {
    teams: Team[],
    showCreationModal: boolean,
    showUserCreationModal: boolean,
    showUserAssignmentModal: boolean,
    currentTeam?:Team,
}

const teamState: TeamState = {
    teams: [],
    showCreationModal: false,
    showUserCreationModal: false,
    showUserAssignmentModal: false,
    currentTeam:undefined,
}

const teamSlice = createSlice(
    {
        name: "team",
        initialState: teamState,
        reducers: {
            clearState: () => teamState,
            setTeams: (state, action: PayloadAction<Team[]>) => {
                state.teams = action.payload
            },
            updateTeams: (state, action: PayloadAction<Team>) => {
                const teamToUpdate = action.payload
                const teamIndex = state.teams.findIndex(t => t.name === teamToUpdate.name)
                if (teamIndex >= 0) {
                    state.teams[teamIndex] = teamToUpdate
                    // setCookie(TEAMS, JSON.stringify(state.teams))
                }
            },
            setShowCreationModal: (state, action: PayloadAction<boolean>) => {
                state.showCreationModal = action.payload
            },
            setShowUserCreationModal: (state, action: PayloadAction<boolean>) => {
                state.showUserCreationModal = action.payload
            },
            setShowUserAssignmentModal: (state, action: PayloadAction<boolean>) => {
                state.showUserAssignmentModal = action.payload
            },
            setCurrentTeam:(state, action:PayloadAction<Team>)=>{
                state.currentTeam=action.payload
            }
        }
    }
)

export default teamSlice.reducer
export const clearTeamState = teamSlice.actions.clearState
export const setTeams=teamSlice.actions.setTeams

export const useTeamActions = () => {

    const dispatch = useAppDispatch()
    const {
        teams,
        showUserAssignmentModal,
        showCreationModal,
        showUserCreationModal,
        currentTeam,
    } = useAppSelector(state => state.team)
    const {
        setShowUserAssignmentModal,
        setShowUserCreationModal,
        setShowCreationModal,
        setTeams,
        updateTeams,
        setCurrentTeam
    } = teamSlice.actions


    return {
        teams, showUserAssignmentModal, showCreationModal, showUserCreationModal,currentTeam,

        setShowUserAssignmentModal: (show:boolean) => {
            dispatch(setShowUserAssignmentModal(show))
        },
        setShowUserCreationModal: (show:boolean) => {
            dispatch(setShowUserCreationModal(show))
        }, setShowCreationModal: (show:boolean) => {
            dispatch(setShowCreationModal(show))
        }, setTeams: (teams:Team[]) => {
            dispatch(setTeams(teams))
        }, updateTeams: (team:Team) => {
            dispatch(updateTeams(team))
        },
        setCurrentTeam:(team:Team)=>{
            dispatch(setCurrentTeam(team))
        }

    }
}


