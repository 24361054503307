export interface Institute {
    origin: string,
    name: string,
}


const institutes = [
    {origin: "PT", name: "INPI"},
    {origin: "EU", name: "EUIPO"},
    {origin: "ES", name: "OEPM"},
    {origin: "BR", name: "INPI_BR"},
]

export const mapInstitute = (origin: string): Institute | undefined => {
    return institutes.find(institute => institute.origin === origin)
}