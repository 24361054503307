import {Container} from "react-bootstrap";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import MyButton from "../../widgets/MyButton";
import RegisterTable from "../../widgets/RegisterTable";
import {Page} from "../../models/Page";
import {BrandRegister} from "../../models/BrandRegister";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useBulletinsThunks} from "../../store/thunks/BulletinsThunks";

const BrandWithoutRepresentatives = () => {

    const {setRegisterPage, registerPage, currentPage, setCurrentPage} = useBulletinsActions()
    const {isLoading} = useIsLoadingActions()
    const {requestRegistersWithoutRepresentative} = useBulletinsThunks()


    useEffect(()=>{
        setRegisterPage({} as Page<BrandRegister[]>)
    },[])


    const intl = useIntl()
    const messages = {
        listRegistersWithoutRepresentatives: intl.formatMessage({id: "brandsWithoutRepresentative.label.listRegistersWithoutRepresentatives"}),
    }

    const handleListRegisterWithoutRepresentatives = () => {
        handleRequestData()
    }

    const handleRequestData = () => {
        setRegisterPage({} as Page<BrandRegister[]>)
        setCurrentPage(0)
        requestRegistersWithoutRepresentative(0)
    }


    return <>
        <Container className={"mt-3"}>
            <WrapperPageableTable table={<RegisterTable />}
                                  searchButton={<MyButton disabled={isLoading}
                                                          label={messages.listRegistersWithoutRepresentatives}
                                                          onClick={handleListRegisterWithoutRepresentatives}/>}
                                  page={registerPage} currentPage={currentPage}
                                  setCurrentPage={setCurrentPage} requestPage={requestRegistersWithoutRepresentative}
            />
        </Container>
    </>
}


export default BrandWithoutRepresentatives