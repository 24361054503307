import ManageIgnoredWords from "./ManageIgnoredWords";
import MyAccordion from "../../widgets/MyAccordion";
import {Container} from "react-bootstrap";
import {AccordionItems} from "../../models/AccordionItems";
import {useIntl} from "react-intl";

import PasswordChangeForm from "./PasswordChangeForm";
import ManageRelatedClasses from "./ManageRelatedClasses";

const User = () => {

    const intl = useIntl()
    const messages = {
        managePassword: intl.formatMessage({id: "profile.label.managePassword"}),
        manageIgnoredWords: intl.formatMessage({id: "profile.label.manageIgnoredWords"}),
        manageRelatedClass: intl.formatMessage({id: "profile.label.manageRelatedClass"}),
    }

    const items = (): AccordionItems[] => {
        return [
            {header: messages.managePassword, body: <PasswordChangeForm/>},
            {header: messages.manageIgnoredWords, body: <ManageIgnoredWords/>},
            {header: messages.manageRelatedClass, body: <ManageRelatedClasses/>},
        ]

    }


    return <>
        <Container className={"mt-2"}>
            <MyAccordion items={items()}/>
        </Container>
    </>
}

export default User