import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Similarity} from "../../models/Similarity";
import {useAppDispatch, useAppSelector} from "../hooks";

interface SimilarityState {
    similarities: Similarity[],
    selectedSimilaritiesId: number[],
    isFirst: boolean,
    isLoading: boolean,
    filter: number,
    origin: string,
}

const similarityState: SimilarityState = {
    similarities: [],
    selectedSimilaritiesId: [],
    isFirst: true,
    isLoading: false,
    filter: 0.3,
    origin: 'PT',
}

const similaritySlice = createSlice(
    {
        name: "similarity",
        initialState: similarityState,
        reducers: {
            clearState: () => similarityState,
            setSimilarities: (state, action: PayloadAction<Similarity[]>) => {
                state.similarities = action.payload
            },
            setSelectedSimilaritiesId: (state, action: PayloadAction<number[]>) => {
                state.selectedSimilaritiesId = action.payload
            },
            setIsFirst: (state, action: PayloadAction<boolean>) => {
                state.isFirst = action.payload
            }, setIsLoading: (state, action: PayloadAction<boolean>) => {
                state.isLoading = action.payload
            },
            setFilter: (state, action: PayloadAction<number>) => {
                state.filter = action.payload
            }, setOrigin: (state, action: PayloadAction<string>) => {
                state.origin = action.payload
            }
        }
    }
)

export default similaritySlice.reducer
export const clearSimilaritiesState = similaritySlice.actions.clearState


export const useSimilarityActions = () => {

    const {
        similarities, selectedSimilaritiesId,
        isFirst,  filter, origin
    } = useAppSelector(state => state.similarity)


    const {
        setSelectedSimilaritiesId, setSimilarities,
        setIsFirst, setFilter, setOrigin
    } = similaritySlice.actions
    const dispatch = useAppDispatch()

    return {
        similarities, selectedSimilaritiesId, isFirst,  filter, origin,
        setSelectedSimilaritiesId: (ids: number[]) => {
            dispatch(setSelectedSimilaritiesId(ids))
        }, setSimilarities: (similarities: Similarity[]) => {
            dispatch(setSimilarities(similarities))
        },
        setIsFirst: (isFirst: boolean) => {
            dispatch(setIsFirst(isFirst))
        },
        setFilter: (filter: number) => {
            dispatch(setFilter(filter))
        }, setOrigin: (origin: string) => {
            dispatch(setOrigin(origin))
        },
    }


}