import {Col, Container, Row} from "react-bootstrap";
import {useTeamActions} from "../../store/slices/TeamSlice";
import React, {useEffect} from "react"
import {array, number, object, string} from "yup";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import MyTextInput from "../../widgets/MyTextInput";
import {WatchingClass} from "../../models/WatchingClass";
import {Colors} from "../../widgets/Colors";
import MyButton from "../../widgets/MyButton";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useUserThunks} from "../../store/thunks/UserThunks";
import {useIntl} from "react-intl";

const isNumberArray = (value: string | undefined) => {
    if (!value) return true
    const values = value.split(",").map(Number)
    return values.every(num => num >= 1 && num <= 45)
}

const areNumbersDistinct = (value: { watchingNumber: number, relatedNumbers: string }[] | undefined) => {
    if (!value) return true
    const numbers = value.map(wc => wc.watchingNumber)
    return new Set(numbers).size === numbers.length
}

const classesSchema = object<WatchingClass[]>().shape({
    classes: array().of(
        object().shape({
            watchingNumber: number().required(),
            // relatedNumbers: array().of(number().required())
            relatedNumbers: string().test(
                "testRelatedNumbers",
                "Numbers should be separated by commas and between 1 and 45",
                (value) => isNumberArray(value)
            ).required()

        })
    ).test("testDistinctNumbers", "Numbers should be Distincts", (value) => areNumbersDistinct(value))
}).required()

const ManageRelatedClasses = () => {
    const {
        getValues,
        register,
        handleSubmit,
        formState: {errors},
        control,
        setValue
    } = useForm({

        resolver: yupResolver(classesSchema)
    });

    const {
        append,
        remove,
        fields,
    } = useFieldArray({
        control,
        name: "classes"
    });

    const intl = useIntl()
    const messages = {
        newWord: intl.formatMessage({id: "profile.label.newClass"}),
        remove: intl.formatMessage({id: "generics.label.remove"}),
        saveChanges: intl.formatMessage({id: "generics.label.saveChanges"}),
        class: intl.formatMessage({id: "profile.label.class"}),
        relatedClasses: intl.formatMessage({id: "profile.label.relatedClasses"}),
    }

    const {currentTeam} = useTeamActions()

    const {updateRelatedClasses} = useUserThunks()

    const {isLoading} = useIsLoadingActions()

    const updateYup = (classes: { watchingNumber: number, relatedNumbers: string }[]) => {
        setValue("classes", classes)
    }

    useEffect(() => {
        if (currentTeam) {
            const classes = currentTeam?.watchingClasses.map(wc => {
                return {
                    watchingNumber: wc.watchingNumber,
                    relatedNumbers: wc.relatedNumbers.join(",")
                }
            })
            updateYup(classes)
        }
    }, [currentTeam]);


    const handleUpdateIgnoredWords = (classes: {
        watchingNumber: number,
        relatedNumbers: string
    }[] | undefined) => {
        if (classes) {
            updateRelatedClasses(classes.map(c => {
                return {
                    watchingNumber: c.watchingNumber,
                    relatedNumbers: c.relatedNumbers.split(",").map(Number)
                } as WatchingClass
            }))
        }
    }

    const handleAddNewRelatedClasses = () => {
        if (getValues("classes")?.filter(wc => {
            return parseInt(`${wc.watchingNumber}`) === 1 && wc.relatedNumbers === ""
        }).length === 0) {
            append({watchingNumber: 1, relatedNumbers: ""})
        }
    }
    return (
        <>
            <Container>

                {fields.map((field, index) => {
                    return <React.Fragment key={index}>
                        <Row>
                            <Col xs={2}>
                                <MyTextInput label={messages.class}
                                             type={"number"}  {...register(`classes.${index}.watchingNumber`)}  />
                            </Col>
                            <Col>
                                <MyTextInput label={messages.relatedClasses}
                                             key={index} {...register(`classes.${index}.relatedNumbers`)}
                                             errorText={(errors.classes && errors.classes.length &&
                                                 errors.classes.length >= index)
                                                 ? errors.classes[index]?.relatedNumbers?.message : ""}
                                />
                            </Col>
                            <Col>

                                <div className={"mt-4"}>
                                    <MyButton label={messages.remove} disabled={isLoading}
                                              onClick={() => remove(index)}
                                              color={Colors.RED}/>
                                </div>

                            </Col>
                        </Row>
                    </React.Fragment>
                })}
                {errors && <p className={"text-danger"}>{errors.classes?.message}</p>}


                <Row lg={"6"}>
                    <MyButton className={"mt-3"} disabled={isLoading} label={messages.newWord}
                              onClick={handleAddNewRelatedClasses}/>
                </Row>

                <Row lg={"6"}>
                    <MyButton className={"mt-5"} label={messages.saveChanges} disabled={isLoading}
                              onClick={handleSubmit((data) => handleUpdateIgnoredWords(data.classes))}
                              color={Colors.GREEN}/>
                </Row>


            </Container>

        </>
    )
}

export default ManageRelatedClasses