import React, {ChangeEvent, useEffect, useState} from "react";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {useKeycloak} from "@react-keycloak/web";
import {mapInstitute} from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import {useIntl} from "react-intl";
import dayjs, {Dayjs} from "dayjs";
import {useSimilarityActions} from "../../store/slices/SimilaritySlice";
import {useSimilarityThunks} from "../../store/thunks/SimilarityThunks";
import DailyWatchDetails from "./DailyWatchDetails";
import MyButton from "../../widgets/MyButton";
import SimilarityTable from "./SimilarityTable";
import {useCustomerActions} from "../../store/slices/CustomerSlice";
import {useCustomerThunks} from "../../store/thunks/CustomerThunks";
import CustomerCheckboxes from "./CustomerCheckboxes";
import MyAccordion from "../../widgets/MyAccordion";
import {SimpleRegister} from "../../models/SimpleRegister";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";

const Similarities = () => {

    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs())
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedRegisters, setSelectedRegisters] = useState<SimpleRegister[]>([]);
    const [allRegisters, setAllRegisters] = useState<SimpleRegister[]>([])

    const intl = useIntl()

    const messages = {
        institute: intl.formatMessage({id: "model.institute"}),
        startDate: intl.formatMessage({id: "generics.label.startDate"}),
        endDate: intl.formatMessage({id: "generics.label.endDate"}),
        publications: intl.formatMessage({id: "similarities.label.publications"}),
        searchSimilarities: intl.formatMessage({id: "similarities.label.searchSimilarities"}),
        minimumSimilarity: intl.formatMessage({id: "similarities.label.minimumSimilarity"}),
        customerSelection: intl.formatMessage({id: "similarities.label.customerSelection"}),
        selectAll: intl.formatMessage({id: "similarities.label.selectAllRegisters"}),
    }
    const {keycloak} = useKeycloak()
    const {similarities, setSelectedSimilaritiesId, setSimilarities, isFirst,
        filter, origin, setOrigin, setFilter
    } = useSimilarityActions()

    const {isLoading}=useIsLoadingActions()
    const {fetchSimilaritiesWithSource, onMonthChange} = useSimilarityThunks()
    const {customers, setCustomers,} = useCustomerActions()

    const {fetchCustomers,} = useCustomerThunks()

    useEffect(() => {
        const simpleRegisters = customers.flatMap(cust => {
            return cust.brandRegisters.map(br => {
                return {
                    institute: br.source,
                    number: br.number,
                    brandText: br.brandText,
                    customerName: cust.name
                } as SimpleRegister
            })
        })

        setAllRegisters(simpleRegisters)
        setSelectedRegisters(simpleRegisters)
    }, [customers])

    useEffect(() => {

        fetchCustomers()
        return () => {
            setCustomers([])
            setIsFirstLoading(true)
            setSimilarities([])
            setAllRegisters([])
            setSelectedRegisters([])
        }
    }, [])

    useEffect(() => {
        setIsFirstLoading(true)
        setStartDate(dayjs())
        setEndDate(dayjs())
        onMonthChange(startDate)
        setIsFirstLoading(false)
        setSimilarities([])
    }, [origin])


    const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedSimilaritiesId([])
        const filter = parseFloat(event.currentTarget.value)
        if (!Number.isNaN(filter)) {
            setFilter(filter)
        }
    }

    const handleSearchSimilarities = async () => {
        fetchSimilaritiesWithSource(startDate, endDate, origin, selectedRegisters)
    }


    const isAllRegistersSelected = () => {
        if (selectedRegisters.length !== allRegisters.length) return false
        return selectedRegisters.every(selReg => allRegisters.some(reg => reg.number === selReg.number && reg.institute === selReg.institute))
    }

    const handleCheckboxChange = () => {
        if (isAllRegistersSelected()) {
            setSelectedRegisters([])
        } else {
            setSelectedRegisters([...allRegisters])
        }

    }

    const items = () => {
        return [
            {
                header: <>{messages.customerSelection}</>,
                body: <>
                    <div>
                        <Form.Check
                            inline
                            key={"1"}
                            label={messages.selectAll}
                            type={"checkbox"}
                            name={"registers"}
                            checked={isAllRegistersSelected()}
                            onChange={() => handleCheckboxChange()}
                        />
                    </div>
                    <CustomerCheckboxes customers={customers} selectedSimpleRegisters={selectedRegisters}
                                        setSelectedSimpleRegisters={setSelectedRegisters}
                                        allSimpleRegisters={allRegisters}/>
                </>
            },
        ]
    }


    const institutes = () => {
        return keycloak.tokenParsed?.institutes?.map((inst: string) => {
            const institute = mapInstitute(inst)
            if (institute) {
                return <option key={institute.origin}
                               value={institute.origin}>{institute.name}</option>
            }
        })
    }

    return <>
        {
            <div style={{margin: "20px"}}>
                {!isFirstLoading &&
                    <>
                        {
                            <MyAccordion key={"customers"} items={items()} defaultAsOpen={false}/>
                        }

                        <MySelect onChange={(evt) => setOrigin(evt.target.value)}
                                  labelId={"institute-dropdown-label"}
                                  label={messages.institute} value={origin}
                                  className={"mb-2"}
                                  selectId={"institute-dropdown"}
                                  data={institutes()}/>

                        <Row>
                            <Col>
                                <DailyWatchDetails
                                    label={messages.startDate}
                                    date={startDate} setDate={setStartDate} />


                            </Col>
                            <Col>
                                <DailyWatchDetails
                                    label={messages.endDate}
                                    date={endDate} setDate={setEndDate}
                                    />
                            </Col>
                        </Row>

                        <MyButton className={"mb-2"} disabled={isLoading} label={messages.searchSimilarities}
                                  onClick={handleSearchSimilarities}/>


                        <Row>
                            {(similarities.length !== 0 && !isFirst) && <React.Fragment>
                                <Form.Label>{messages.minimumSimilarity}: {(filter * 100).toFixed(2)}%</Form.Label>
                                <Form.Range value={filter} onChange={handleRangeChange} min={0} max={1}
                                            step={0.01}/>
                            </React.Fragment>}
                        </Row>

                        <SimilarityTable startDate={startDate} endDate={endDate}/>
                    </>
                }
                {isLoading && <Spinner animation={"border"}/>}
            </div>
        }

    </>

}


export default Similarities