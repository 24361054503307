import MyModal from "../../../widgets/MyModal";
import MyButton from "../../../widgets/MyButton";
import {Colors} from "../../../widgets/Colors";


interface Props {
    show: boolean,
    setShow: (show: boolean) => void,
    brandTexts: string[],
}

const AnalysisModal = ({show, setShow, brandTexts}: Props) => {
    const handleClose = () => {
        setShow(false)
    }

    const formBody = () => {
        return <>
            {
                brandTexts?.map((text, index) => {
                    return <div key={index}>
                        <label> {index === 0 ? "Vigiada" : "Ofensora"} </label>
                        <p>{text}</p>
                    </div>

                })
            }
        </>
    }


    const formFooter = () => {
        return <>
            <MyButton label={"fechar"} onClick={handleClose} color={Colors.GRAY}/>
        </>

    }

    return <>
        <MyModal body={formBody()} footer={formFooter()} title={`Detalhes do Registo`} show={show} size={"lg"}
                 handleClose={handleClose}/>
    </>
}

export default AnalysisModal