import {KeycloakUser} from "../../models/keycloak/KeycloakUser";
import {KeycloakGroup} from "../../models/keycloak/KeycloakGroup";
import {requests} from "./API";

const baseUrl="/keycloak"
const KeycloakRequests = {
    getUsers: () => requests.get<KeycloakUser[]>(`${baseUrl}/users`,),
    getUserByUsername: (username:string) => requests.get<KeycloakUser|null>(`${baseUrl}/users/${username}/username`,),
    getGroups: () => requests.get<KeycloakGroup[]>(`${baseUrl}/groups`),

    createUser: (user: KeycloakUser,) => requests.post<string, KeycloakUser>(`${baseUrl}/users`, user),
    createGroup: (group: KeycloakGroup,) => requests.post<string, KeycloakGroup>(`${baseUrl}/groups`, group),

    sendUserPostCreationMail: (userId: string) => requests.put<boolean, null>(`${baseUrl}/users/${userId}/sendEmail`, null),
    resetPassword: (userId?: string) => requests.put<boolean, null>(`${baseUrl}/users/${userId}/resetPassword`, null),

    updateGroupInstitute: (groupId: string, group: KeycloakGroup) => requests.put<boolean, KeycloakGroup>(`${baseUrl}/groups/${groupId}`, group),
    deleteUser: (userId: string,) => requests.delete<boolean>(`${baseUrl}/users/${userId}`),

    deleteGroup: (groupId: string,) => requests.delete<boolean>(`${baseUrl}/groups/${groupId}`),
}

export default KeycloakRequests