import {Form, Modal} from "react-bootstrap";

import MyTextInput from "../../widgets/MyTextInput";
import {useCustomerActions} from '../../store/slices/CustomerSlice'
import {useCustomerThunks} from "../../store/thunks/CustomerThunks";
import {Customer} from "../../models/Customer";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import {object, string} from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import MySelect from "../../widgets/MySelect";

const CustomerSchema = object<Customer>()
    .shape({
        name: string().required(),
        email: string().required(),
        address: string().required(),
        displayName: string().required(),
        language: string().required(),
    }).required()

const CustomerFormModal = () => {
    const intl = useIntl()
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(CustomerSchema)
    });


    const messages = {
        newCustomer: intl.formatMessage({id: "customers.label.newCustomer"}),
        name: intl.formatMessage({id: "model.name"}),
        email: intl.formatMessage({id: "model.email"}),
        address: intl.formatMessage({id: "model.address"}),
        displayName: intl.formatMessage({id: "model.displayName"}),
        language: intl.formatMessage({id: "model.language"}),
        close: intl.formatMessage({id: "generics.label.close"}),
        saveChanges: intl.formatMessage({id: "generics.label.saveChanges"}),
    }

    const {customers, setCustomers, setShowNewCustomerModal, showNewCustomerModal} = useCustomerActions()

    const {createCustomer} = useCustomerThunks()

    const handleClose = () => {
        setShowNewCustomerModal(false)
    };

    const handleSaveNewCustomer = async (data: Customer) => {
        const handlerResult = (customer: Customer | undefined) => {
            if (customer) {
                setCustomers([...customers, customer])
                handleClose()
                reset()
            }
        }
        createCustomer(data, handlerResult)
    }

    return <>
        <Modal show={showNewCustomerModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{messages.newCustomer}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <MyTextInput {...register("name")}
                                 label={messages.name}
                                 errorText={errors.name?.message}

                    />
                    <MyTextInput {...register("email")}
                                 label={messages.email}
                                 errorText={errors.email?.message}
                    />
                    <MyTextInput {...register("address")}
                                 label={messages.address}
                                 errorText={errors.address?.message}
                    />
                    <MyTextInput {...register("displayName")}
                                 label={messages.displayName}
                                 errorText={errors.displayName?.message}
                    />
                    <MySelect {...register("language")} labelId={""} errorText={errors.language?.message}
                              label={messages.language} className={""} selectId={""} data={
                        [
                            <option key={"PT"} value={"PT"}>PT</option>,
                            <option key={"EN"} value={"EN"}>EN</option>,
                        ]
                    }/>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <MyButton label={messages.close} onClick={handleClose} color={Colors.GRAY}/>
                <MyButton label={messages.saveChanges} onClick={handleSubmit(handleSaveNewCustomer)}/>
            </Modal.Footer>
        </Modal>
    </>

}


export default CustomerFormModal;