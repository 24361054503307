import {requests} from "./API";
import {BrandRegister} from "../../models/BrandRegister";
import {Page} from "../../models/Page";

const basePath = "/bulletins"



const BulletinsRequests = {
    getAllRegistersByDateAndSource: (date: string, source: string, page = 0) => requests.get<Page<BrandRegister[]>>(`${basePath}/daily/${date}/${source}?page=${page}`),
    getPTBrandRegistersWithoutRepresentative: (page: number) => requests.get<Page<BrandRegister[]>>(`${basePath}/representative/empty?page=${page}`),
    getBrandRegisterByRepresentativeName: (representativeName: string, page: number) => requests.get<Page<BrandRegister[]>>(`${basePath}/representative/${representativeName}?page=${page}`),
    deleteRegisters: (date: string, institute: string) => requests.delete<boolean>(`${basePath}/${date}/${institute}`)
}

export default BulletinsRequests
