import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {Representative} from "../../models/Representative";
import {AsyncPaginate} from "react-select-async-paginate";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import RegisterTable from "../../widgets/RegisterTable";
import {useBulletinsThunks} from "../../store/thunks/BulletinsThunks";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {Page} from "../../models/Page";
import {BrandRegister} from "../../models/BrandRegister";

type Pageable = {
    page: number
}

const Representatives = () => {

    const {isLoading,} = useIsLoadingActions()
    const {requestRegistersByRepresentativeName, getAllRepresentatives} = useBulletinsThunks()
    const {
        registerPage,
        currentPage,
        setCurrentPage,
        setSelectedRepresentative,
        selectedRepresentative,setRegisterPage
    } = useBulletinsActions()

    const intl = useIntl()
    const messages = {
        representatives: intl.formatMessage({id: "model.representatives"}),
        listRegisters: intl.formatMessage({id: "representatives.label.listRegisters"}),
        noResults: intl.formatMessage({id: "generics.label.noResults"}),
    }

    useEffect(()=>{
        setRegisterPage({} as Page<BrandRegister[]>)
    },[])

    const handleListBrands = async () => {
        await handleRequestData()
    }

    async function loadOptions(search: string, _: unknown, {page}: Pageable) {
        const response = await getAllRepresentatives(page)
        return {
            options: response.content,
            hasMore: !response.last,
            additional: {
                page: page + 1
            }
        }
    }

    const handleRequestData = async () => {
        setCurrentPage(0)
        requestPage(0)
    }
    const requestPage = (page: number) => {
        requestRegistersByRepresentativeName(selectedRepresentative.name, page)
    }

    const getSearchButton = () => <MyButton disabled={isLoading} label={messages.listRegisters}
                                            onClick={handleListBrands}
                                            className={"ms-2"}/>

    return <>
        <Container className={"mt-2"}>
            <Row className={"mb-2"}>
                <Col>
                    <AsyncPaginate
                        onChange={setSelectedRepresentative}
                        value={selectedRepresentative}
                        loadOptions={loadOptions}
                        getOptionLabel={(option: Representative) => option.name}
                        getOptionValue={(option: Representative) => option.name}
                        additional={{page: 0}}
                        placeholder={"Select"}
                    />
                </Col>
            </Row>
            <WrapperPageableTable table={<RegisterTable />}
                                  searchButton={getSearchButton()} page={registerPage} currentPage={currentPage}
                                  setCurrentPage={setCurrentPage} requestPage={requestPage}/>

        </Container>
    </>
}

export default Representatives