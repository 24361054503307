import {useAppDispatch} from "../hooks";
import KeycloakRequests from "../../api/customer/KeycloakRequests";
import {toast} from "react-toastify";
import UserRequests from "../../api/customer/UserRequests";
import {IgnoredWord} from "../../models/IgnoredWord";
import {useIsLoadingActions} from "../slices/IsLoadingSlice";
import {WatchingClass} from "../../models/WatchingClass";


const resetPassword = (userId: string) => {
    const {resetPassword} = KeycloakRequests
    return async () => {
        return resetPassword(userId)
    }
}

const getIgnoredWords = () => {

    const {getIgnoredWords} = UserRequests

    return async () => {
        return getIgnoredWords()
    }
}

const updateIgnoredWords = (ignoredWords: IgnoredWord[]) => {
    const {updateIgnoredWords} = UserRequests

    return async () => {
        return updateIgnoredWords(ignoredWords)
    }
}

const updateRelatedClasses = (watchingClasses: WatchingClass[]) => {
    const {updateRelatedClasses} = UserRequests

    return async () => {
        return updateRelatedClasses(watchingClasses)
    }
}

export const useUserThunks = () => {

    const dispatch = useAppDispatch()
    const {setIsLoading} = useIsLoadingActions()


    return {

        resetPassword: (userId: string) => {
            setIsLoading(true)
            dispatch(resetPassword(userId)).then(result => {
                if (result) {
                    toast.info("Password reset email was sent")
                } else {
                    toast.error("Password reset email failed to be sent. Contact please the administrator")
                }
            }).finally(() => {
                setIsLoading(false)
            })
        },

        getIgnoredWords: (handleResult: (data: IgnoredWord[]) => void) => {
            setIsLoading(true)
            dispatch(getIgnoredWords()).then(result => handleResult(result)).finally(() => {
                setIsLoading(false)
            })
        },
        updateIgnoredWords: (ignoredWords: IgnoredWord[]) => {
            setIsLoading(true)
            dispatch(updateIgnoredWords(ignoredWords)).then(result => {
                if (result && result.length > 0) {
                    toast("Mudança nas palavras ignoradas realizada com sucesso")
                } else {
                    toast("Erro ao mudar as palavras ignoradas")
                }
            }).finally(() => {
                setIsLoading(false)
            })
        },
        updateRelatedClasses: (watchingClasses: WatchingClass[]) => {
            setIsLoading(true)
            dispatch(updateRelatedClasses(watchingClasses))
                .then(result => {
                    if (result && result.length >0) {
                        toast("Atualização de classes relacionadas com sucesso")
                    } else {
                        toast("Erro ao atualizar classes relacionadas")
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

    }

}