import {requests} from "./API";
import {BrandRegister} from "../../models/BrandRegister";

const baseUrl="registers"
const BrandRegisterRequests = {
    getRegisterDataFromAPI: (number:string, source:string, customerId:number) => requests.get<BrandRegister>(`${baseUrl}/api/${number}/${source}/${customerId}`),
    createRegister: (customerId:number, brandRegister:BrandRegister) => requests.post<BrandRegister,BrandRegister>(`${baseUrl}/${customerId}`, brandRegister),
    updateRegister: (customerId:number, brandRegister:BrandRegister) => requests.put<BrandRegister,BrandRegister>(`${baseUrl}/${customerId}`, brandRegister),
    deleteRegister: (brandRegisterId:number) => requests.delete<void>(`${baseUrl}/${brandRegisterId}`),
}

export default BrandRegisterRequests
