import {configureStore} from "@reduxjs/toolkit";
import customerReducer from "./slices/CustomerSlice";
import watchTaskReducer from "./slices/WatchTaskSlice";
import teamReducer from "./slices/TeamSlice";
import similarityReducer from "./slices/SimilaritySlice";
import incidentsWatchTaskReducer from "./slices/IncidentsWatchTaskSlice";
import userReducer from "./slices/UserSlice";
import isLoadingReducer from "./slices/IsLoadingSlice";
import representativeReducer from "./slices/RepresentativeSlice";
import renewalReducer from "./slices/RenewalSlice";
import bulletinsReducer  from "./slices/BulletinsSlice"
import brandRegisterReducer from "./slices/BrandRegisterSlice";
import keycloakSliceReducer from "./slices/KeycloakSlice";


const store = configureStore({
    reducer: {
        customer: customerReducer,
        watchTask: watchTaskReducer,
        team: teamReducer,
        similarity: similarityReducer,
        incident: incidentsWatchTaskReducer,
        user: userReducer,
        isLoading: isLoadingReducer,
        representative: representativeReducer,
        renewal:renewalReducer,
        bulletins:bulletinsReducer,
        brandRegisters:brandRegisterReducer,
        keycloak:keycloakSliceReducer,
    },
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
