import React, {ChangeEvent, useMemo} from "react";
import {toast} from "react-toastify";
import {Spinner, Table} from "react-bootstrap"
import MyTextInput from "../../widgets/MyTextInput";
import {Box, FormControl,} from "@mui/material";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {KeycloakUser} from "../../models/keycloak/KeycloakUser";
import {KeycloakGroup} from "../../models/keycloak/KeycloakGroup";
import {useIntl} from "react-intl";
import {boolean, object, string} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useKeycloakThunks} from "../../store/thunks/KeycloakThunks";
import {useKeycloakAction} from "../../store/slices/KeycloakSlice";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";


const UserSchema = object().shape({
    username: string().required(),
    firstName: string().required(),
    lastName: string().required(),
    email: string().required(),
    enabled: boolean().default(true)
}).required()

const KeycloakUserManagement = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(UserSchema)
    })

    const {users, setUsers, groups} = useKeycloakAction()
    const {isLoading} = useIsLoadingActions()
    const {createUser, sendUserPostCreationMail, deleteUser, associateUserToTeam} = useKeycloakThunks()
    const groupsOptions = useMemo(() =>
            [<option key={-1}></option>, ...groups.map(group => {
                return <option key={group.name} value={group.name}>{group.name}</option>
            })]
        , [groups])

    const intl = useIntl()
    const messages = {
        newUser: intl.formatMessage({id: "keycloak.label.newUser"}),
        username: intl.formatMessage({id: "keycloak.label.username"}),
        firstName: intl.formatMessage({id: "keycloak.label.firstName"}),
        lastName: intl.formatMessage({id: "keycloak.label.lastName"}),
        email: intl.formatMessage({id: "model.email"}),
        createUser: intl.formatMessage({id: "keycloak.label.createUser"}),
        team: intl.formatMessage({id: "keycloak.label.team"}),
        associateTeam: intl.formatMessage({id: "keycloak.label.associateTeam"}),
        remove: intl.formatMessage({id: "generics.label.remove"}),
        institute: intl.formatMessage({id: "model.institute"}),
    }


    const handleAssociateGroup = async (user: KeycloakUser) => {
        associateUserToTeam(user)
    }

    const handleChangeUserGroup = (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>, user: KeycloakUser) => {
        const newUser={...user,groups:[groups.find(g => g.name === evt.target.value) ?? {} as KeycloakGroup]}
        const userIndex = users.findIndex(u => u.username === user.username)
        const updatedUsers = [...users]
        updatedUsers[userIndex] = newUser
        setUsers(updatedUsers)
    }
    const handleCreateNewUser = async (data: KeycloakUser) => {

        console.log(errors)

        createUser(data, (newUserId: string) => {
            if (newUserId !== "") {
                sendUserPostCreationMail(newUserId, () => {
                    setUsers([...users, {...data, id: newUserId}])
                    reset()
                    toast.info("User created")
                })

            } else {
                toast.error("Could not create user")
            }
        })
    }

    const handleRemoveUser = async (user: KeycloakUser) => {
        deleteUser(user)
    }

    return <>

        <div className={"mt-2 mb-2 p-2"}>
            {isLoading && <Spinner animation={"border"}/>}
            <FormControl>
                <Box>
                    <h4>{messages.newUser}</h4>
                    <MyTextInput inputClassName={"me-2"} label={messages.username}
                                 {...register("username")} errorText={errors.username?.message}
                    />
                    <MyTextInput inputClassName={"me-2"} label={messages.firstName}
                                 {...register("firstName")} errorText={errors.firstName?.message}
                    />
                    <MyTextInput inputClassName={"me-2"} label={messages.lastName}
                                 {...register("lastName")} errorText={errors.lastName?.message}
                    />
                    <MyTextInput label={messages.email}
                                 {...register("email")} errorText={errors.email?.message}
                    />
                    <div className={"mt-2"}>
                        <MyButton label={messages.createUser}
                                  onClick={handleSubmit((data) => handleCreateNewUser(data))}/>
                    </div>
                </Box>
            </FormControl>
        </div>

        <Table striped bordered hover>
            <thead>
            <tr>
                <th>{messages.username}</th>
                <th align={"center"}>{messages.team}</th>
                <th align={"center"}></th>
            </tr>
            </thead>
            <tbody>
            {users.map(user => (
                <tr key={user.username}>
                    <td>{user.username}</td>
                    <td align={"center"}>
                        <MySelect onChange={(evt) => handleChangeUserGroup(evt, user)} labelId={""}
                                  label={messages.institute}
                                  value={(user.groups && user?.groups[0]?.name) ?? ""} className={"mb-2"} selectId={""}
                                  data={groupsOptions}/>

                        <MyButton className={"ms-2 mt-2"} label={messages.associateTeam}
                                  onClick={() => handleAssociateGroup(user)} color={Colors.GREEN}/>
                    </td>
                    <td align={"center"}>
                        <MyButton label={messages.remove} onClick={() => handleRemoveUser(user)} color={Colors.RED}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>

    </>
}

export default KeycloakUserManagement