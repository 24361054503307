import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ChangeEvent, useState} from "react";
import {toast} from "react-toastify";
import SimilarityTable from "./SimilarityTable";
import MyDatePicker from "../../widgets/MyDatePicker";
import dayjs from "dayjs";
import {useSimilarityThunks} from "../../store/thunks/SimilarityThunks";
import MyTextInput from "../../widgets/MyTextInput";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {useSimilarityActions} from "../../store/slices/SimilaritySlice";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";

const FreeSearch = () => {

    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [brandText, setBrandText] = useState("")
    const [observingClass, _] = useState(1)
    const {fetchFreeSearch} = useSimilarityThunks()
    const {setSimilarities,filter,setFilter,isFirst,setIsFirst}=useSimilarityActions()
    const {isLoading,setIsLoading}=useIsLoadingActions()

    const intl=useIntl()
    const messages={
        brandText:intl.formatMessage({id:"model.brandText"}),
        class:intl.formatMessage({id:"model.class"}),
        startDate:intl.formatMessage({id:"generics.label.startDate"}),
        endDate:intl.formatMessage({id:"generics.label.endDate"}),
        search:intl.formatMessage({id:"generics.label.search"}),
    }

    const handleSearch = async () => {
        setSimilarities([])
        setIsLoading(true)
        if (startDate <= endDate) {
            fetchFreeSearch(brandText, observingClass, startDate, endDate)
        } else {
            toast("Data fim tem de ser posterior à data de início")
        }
        setIsFirst(false)
    }

    const handleBrandTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBrandText(event.currentTarget.value)
    }

    const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = parseInt(event.currentTarget.value)
        if (!isNaN(filter)) {
            setFilter(filter)
        }
    }
    const isDisable = () => {
        return brandText.length === 0 || observingClass === 0 || !startDate || !endDate
    }

    return <>

        <Container className={"mb-2 mt-5"}>
            <Row>
                <Col>
                    <MyTextInput name={"brandText"} label={messages.brandText} value={brandText} onChange={handleBrandTextChange}/>
                </Col>
                <Col>
                    <MyTextInput type={"number"} name={"observingClass"} label={messages.class} value={observingClass}
                                 onChange={handleBrandTextChange}/>
                </Col>
                <Col>
                    <MyDatePicker label={messages.startDate} date={startDate}
                                  onChangeDate={(date: dayjs.Dayjs) => setStartDate(date)}/>
                </Col>
                <Col>
                    <MyDatePicker label={messages.endDate} date={endDate}
                                  onChangeDate={(date: dayjs.Dayjs) => setEndDate(date)}/>
                </Col>
            </Row>
        </Container>

        <Container className={"mb-2"}>
            <MyButton label={messages.search} onClick={handleSearch} disabled={isDisable()}/>
        </Container>

        {!isFirst && <Container className={"mb-2"}>
            <Form.Label>Semelhança Mínima: {(filter * 100).toFixed(2)}%</Form.Label>
            <Form.Range value={filter} onChange={handleRangeChange} min={0} max={1} step={0.01}/>
        </Container>}

        <Container>
            {isLoading && <Spinner animation={"border"} size={"sm"}/>}
            <SimilarityTable startDate={startDate} endDate={endDate}/>
        </Container>

    </>
}

export default FreeSearch;