import {useIntl} from "react-intl";
import {useIncidentsWatchTaskActions} from "../../store/slices/IncidentsWatchTaskSlice";
import React, {useEffect} from "react";
import dayjs from "dayjs";
import {Incident} from "../../models/Incident";
import {useIncidentThunks} from "../../store/thunks/IncidentThunks";
import {IncidentWatchTask} from "../../models/IncidentWatchTask";
import {friendlyDate} from "../../utils/DateUtils";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import MyDatePickerIncidents from "../../widgets/MyDatePickerIncidents";
import MyButton from "../../widgets/MyButton";
import IncidentDailyWatchDetails from "./IncidentDailyWatchDetails";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";


const IncidentForm = () => {

    const intl = useIntl()
    const messages = {
        searchIncidents: intl.formatMessage({id: "incidents.label.searchIncidents"}),
        noResults: intl.formatMessage({id: "generics.label.noResults"}),
    }

    const {updateWatchTaskList, watchTaskList,selectedInstitute} = useIncidentsWatchTaskActions()

    const {
        startDate, setStartDate, incidents,
        setIncidents, isFirst, setIsFirst
    } = useIncidentsWatchTaskActions()
    const {isLoading, setIsLoading} = useIsLoadingActions()

    const {getIncidents, getWatchTaskList, getMonthSummary} = useIncidentThunks()

    useEffect(() => {
        getMonthSummary(startDate, selectedInstitute)

    }, [selectedInstitute]);


    const handleGetIncident = async () => {
        setIsFirst(false)
        setIncidents([])

        const handleWatchTaskResult = async (watchList: IncidentWatchTask[] | undefined) => {
            if (watchList) {
                updateWatchTaskList(watchList)
            }
        }
        const handleResult = async (result: Incident[] | undefined) => {
            if (result) {
                setIncidents(result)
                getWatchTaskList(dayjs(startDate), selectedInstitute, handleWatchTaskResult)
            }
            setIsLoading(false)
        }
        getIncidents(dayjs(startDate), selectedInstitute, handleResult)
        setIsLoading(true)
    }

    const filteredWatchTaskByOrigin = () => {

        return watchTaskList?.filter(task => task.origin === selectedInstitute) ?? []
    }

    const tasksDateAndInstitute = (date: dayjs.Dayjs, institute: string) => {
        return watchTaskList?.filter(task => task.dateWatch === friendlyDate(date) && task.origin === institute) ?? []
    }

    const onMonthChange = async (date: dayjs.Dayjs) => {
        const existsTask = watchTaskList?.find(wt => date.isSame(dayjs(wt.dateWatch), 'month'))
        if (!existsTask) {
            getMonthSummary(friendlyDate(date), selectedInstitute)
        }
    }

    return <>
        <Container>


            <Row className={"mt-3"}>
                <Col>
                    <MyDatePickerIncidents
                        incidentWatchList={filteredWatchTaskByOrigin()} date={dayjs(startDate)}
                        onChangeDate={(date) => setStartDate(friendlyDate(date))}
                        onMonthChange={onMonthChange}/>
                    <IncidentDailyWatchDetails tasks={tasksDateAndInstitute(dayjs(startDate), selectedInstitute)}/>
                </Col>
            </Row>
            <div className={"mt-3"}>

                <MyButton label={messages.searchIncidents} onClick={handleGetIncident}/>
            </div>
            {isLoading && <Spinner animation={"border"}/>}

            {
                (incidents.length === 0 && !isLoading && !isFirst) && <div>{messages.noResults}</div>
            }
            {
                incidents.map(
                    ({register: {number}, image}) => {
                        return <React.Fragment key={number}>
                            <div> Processo: {number}</div>
                            <figure>
                                <img style={{borderStyle: "solid", borderColor: "black", borderWidth: "1px"}}
                                     alt={`Incident for ${number}`} width={1200}
                                     src={`data:image/png;base64,${image}`}/>
                            </figure>
                        </React.Fragment>
                    }
                )

            }
        </Container>
    </>
}

export default IncidentForm