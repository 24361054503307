import React from "react";
import {Container} from "react-bootstrap";
import {RepresentativeCount} from "../../models/RepresentativeCount";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {Page} from "../../models/Page";
import RepresentativeStatisticsTable from "./RepresentativeStatisticsTable";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";
import {useBulletinsThunks} from "../../store/thunks/BulletinsThunks";

const RepresentativeStatistics = () => {

    const {isLoading, setIsLoading} = useIsLoadingActions()
    const {representativeCount, setRepresentatives, setCurrentPage, currentPage} = useBulletinsActions()
    const {getRepresentativesBrandsCounts} = useBulletinsThunks()

    const intl = useIntl()

    const messages = {
        search: intl.formatMessage({id: "generics.label.search"}),
    }
    const handleRequestInitialData = async () => {
        setRepresentatives({} as Page<RepresentativeCount[]>)
        setIsLoading(true)
        setCurrentPage(0)
        await requestPage(0)
        setIsLoading(false)
    }

    const requestPage = async (page: number) => {
        const result = await getRepresentativesBrandsCounts(page)
        if (result) {
            setRepresentatives(result)
        }
    }

    return <>
        <Container className={"mt-3"} fluid>
            <WrapperPageableTable
                searchButton={<MyButton disabled={isLoading} label={messages.search} onClick={handleRequestInitialData}
                                        className={"mt-2"}/>}
                table={<RepresentativeStatisticsTable />}
                page={representativeCount} currentPage={currentPage}
                setCurrentPage={setCurrentPage} requestPage={requestPage}
            />
        </Container>
    </>
}
export default RepresentativeStatistics