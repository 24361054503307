import {Button} from "react-bootstrap";
import {Colors} from "./Colors";

interface Props{
    label:string
    onClick:(arg:any)=>void
    color?:Colors
    className?:string
    disabled?:boolean
}

const MyButton=({label,onClick,color=Colors.BLUE,className="", disabled=false}:Props)=>{

    const capitalizedLabel = label.split(" ").map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ")

    return <Button disabled={disabled} className={className} variant={color} onClick={onClick}>{capitalizedLabel}</Button>
}

export default MyButton