import {ChangeEvent, useState} from "react";
import MyTextInput from "../../widgets/MyTextInput";
import RenewalsTable from "./RenewalsTable";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {Renewal} from "../../models/Renewal";
import {useRenewalThunks} from "../../store/thunks/RenewalThunks";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import {useRenewalsActions} from "../../store/slices/RenewalSlice";

const RenewalsByYear = () => {
    const {fetchRenewalsByYear} = useRenewalThunks()
    const {setRenewalsByYear,yearRenewal:yearRenewal,setYear,renewalsByYear}=useRenewalsActions()

    const intl=useIntl()
    const messages={
        year:intl.formatMessage({id:"model.year"}),
        search:intl.formatMessage({id:"generics.label.search"}),
    }

    const handleYearSearch = async () => {

        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este ano")
                }
                setRenewalsByYear(result)
            }
        }

        if (yearRenewal >= dayjs().year()) {
            fetchRenewalsByYear(yearRenewal, handleResult)
        } else {
            toast("Valor inválido para ano")
        }
    }


    const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
        const year = parseInt(event.target.value)
        if (!isNaN(year)) {
            setYear(year)
        }
    }

    return <>
        <MyTextInput type={"number"} inputClassName={"w-25"} value={yearRenewal} onChange={handleYearChange} label={messages.year}/>
        <MyButton className={"mt-2 mb-2 ms-2"} label={messages.search} onClick={handleYearSearch}/>
        <RenewalsTable renewalData={renewalsByYear}/>
    </>
}

export default RenewalsByYear