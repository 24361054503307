import {useAppDispatch} from "../hooks";
import BulletinsRequests from "../../api/bulletins/BulletinsRequests";
import {useBulletinsActions} from "../slices/BulletinsSlice";
import {useIsLoadingActions} from "../slices/IsLoadingSlice";
import RepresentativesRequests from "../../api/bulletins/RepresentativesRequests";
import StatisticsRequests from "../../api/bulletins/StatisticsRequests";


const getBrandRegisterByRepresentativeName = (name: string, page: number) => {
    const {getBrandRegisterByRepresentativeName} = BulletinsRequests

    return async () => {
        return getBrandRegisterByRepresentativeName(name, page)
    }
}

const getPTBrandRegistersWithoutRepresentative = (page: number) => {
    const {getPTBrandRegistersWithoutRepresentative} = BulletinsRequests
    return async () => {
        return getPTBrandRegistersWithoutRepresentative(page)
    }
}

const getAllRepresentatives=(page:number)=>{
    const {getAllRepresentatives}=RepresentativesRequests

    return async ()=>{
        return getAllRepresentatives(page)
    }
}

const getRepresentativesBrandsCounts=(page:number)=>{
    const {getRepresentativesBrandsCounts} = StatisticsRequests

    return async ()=>{
        return getRepresentativesBrandsCounts(page)
    }
}

const getTitularWithoutRepresentatives=(page:number)=>{
    const {getTitularWithoutRepresentatives} = StatisticsRequests

    return async ()=>{
        return getTitularWithoutRepresentatives(page)
    }

}

const getAllRegistersByDateAndSource=(publicationDate:string,selectedInstitute:string,page:number)=>{
    const {getAllRegistersByDateAndSource} = BulletinsRequests

    return async ()=>{
        return getAllRegistersByDateAndSource(publicationDate,selectedInstitute,page)
    }
}

export const useBulletinsThunks = () => {


    const {setRegisterPage} = useBulletinsActions()
    const {setIsLoading}=useIsLoadingActions()


    const dispatch = useAppDispatch()


    return {

        requestRegistersByRepresentativeName: (name: string, page: number) => {
            setIsLoading(true)
            dispatch(getBrandRegisterByRepresentativeName(name, page)).then(result => {
                if (result) {
                    setRegisterPage(result)
                }
            }).finally(()=>{
                setIsLoading(false)
            })
        },

        requestRegistersWithoutRepresentative: (page: number) => {
            setIsLoading(true)
            dispatch(getPTBrandRegistersWithoutRepresentative(page)).then(result => {
                if (result) {
                    setRegisterPage(result)
                }
            }).finally(()=>{
                setIsLoading(false)
            })

        },

        getAllRepresentatives:(page:number)=>{
            return dispatch(getAllRepresentatives(page))
        },

        getRepresentativesBrandsCounts:(page:number)=>{
            return dispatch(getRepresentativesBrandsCounts(page))
        },
        getTitularWithoutRepresentatives:(page:number)=>{
            return dispatch(getTitularWithoutRepresentatives(page))
        },

        getAllRegistersByDateAndSource:(publicationDate:string,selectedInstitute:string,page:number)=>{
            return dispatch(getAllRegistersByDateAndSource(publicationDate,selectedInstitute,page))
        }



    }
}