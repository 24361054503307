import {requests} from "./API";
import {Customer} from "../../models/Customer";
import {CustomerClass} from "../../models/CustomerClass";


const basePath = "/customer"
const CustomerRequests = {
    createCustomer: (customer: Customer) => requests.post<Customer, Customer>(basePath, customer),
    updateCustomer: (id: number, customer: Customer) => requests.put<Customer, Customer>(`${basePath}/${id}`, customer),
    removeCustomer: (id: number) => requests.delete<void>(`${basePath}/${id}`),
    updateObservingClassToCustomer: (id: number, classes: CustomerClass[]) => requests.put<Customer, CustomerClass[]>(`${basePath}/classes/${id}`, classes),

    getCustomers: () => requests.get<Customer[]>(`${basePath}`),
}

export default CustomerRequests;