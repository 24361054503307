import MyButton from "../../widgets/MyButton";
import {useKeycloak} from "@react-keycloak/web";
import {useUserThunks} from "../../store/thunks/UserThunks";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {Spinner} from "react-bootstrap";
import React from "react";
import {useIntl} from "react-intl";

const PasswordChangeForm = () => {

    const {keycloak} = useKeycloak()
    const {resetPassword} = useUserThunks()

    const {isLoading}=useIsLoadingActions()

    const intl = useIntl()

    const messages = {
        sendPasswordResetEmail: intl.formatMessage({id: "profile.label.sendPasswordResetEmail"}),

    }


    const handleSendResetPasswordLink = async () => {
        const userId = keycloak.idTokenParsed?.sub
        if(userId){
            resetPassword(userId)
        }
    }

    return (
        <>
            <MyButton disabled={isLoading} label={messages.sendPasswordResetEmail} onClick={handleSendResetPasswordLink}/>
            {isLoading && <Spinner animation={"border"}/>}
        </>
    )

}
export default PasswordChangeForm