import MyModal from "../../widgets/MyModal";
import React, {useEffect} from "react";

import {Col, Row} from "react-bootstrap";
import {useCustomerActions} from "../../store/slices/CustomerSlice";
import {useCustomerThunks} from "../../store/thunks/CustomerThunks";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import {Colors} from "../../widgets/Colors";
import {useIntl} from "react-intl";
import MyTextInput from "../../widgets/MyTextInput";
import {Customer} from "../../models/Customer";
import {useForm} from "react-hook-form";


const CustomerDetailsModal = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue
    } = useForm<Customer>();

    const intl = useIntl()
    const messages = {
        updateDetailsOf: intl.formatMessage({id: "customers.label.updateDetailsOf"}),
        email: intl.formatMessage({id: "model.email"}),
        address: intl.formatMessage({id: "model.address"}),
        displayName: intl.formatMessage({id: "model.displayName"}),
        language: intl.formatMessage({id: "model.language"}),
        close: intl.formatMessage({id: "generics.label.close"}),
        saveChanges: intl.formatMessage({id: "generics.label.saveChanges"}),
        isRequired: intl.formatMessage({id: "generics.errors.isRequired"}),
    }

    const getErrorMessage = (error?: string) => {
        switch (error) {
            case "emailError": {
                return `${messages.email} ${messages.isRequired}`
            }
            case "addressError": {
                return `${messages.address} ${messages.isRequired}`
            }
            case "displayNameError": {
                return `${messages.displayName} ${messages.isRequired}`
            }
            case "languageError": {
                return `${messages.language} ${messages.isRequired}`
            }
        }

    }

    const {showDetailsModal, changeDetailsModal, selectedCustomer,} = useCustomerActions()
    const {updateCustomer} = useCustomerThunks()


    useEffect(() => {

        if (selectedCustomer.id) {
            setValue("id", selectedCustomer.id)
        }
        setValue("name", selectedCustomer.name)
        setValue("address", selectedCustomer.address)
        setValue("email", selectedCustomer.email)
        setValue("displayName", selectedCustomer.displayName)
        setValue("language", selectedCustomer.language ?? "PT")

        return () => {
            reset()
        }
    }, [selectedCustomer])

    const handleClose = () => {
        changeDetailsModal(false)
    };

    const handleUpdateCustomer = async (customer: Customer) => {
        updateCustomer(customer)
    }

    const formFooter = () => {
        return <>
            <MyButton label={messages.close} onClick={handleClose} color={Colors.GRAY}/>
            <MyButton label={messages.saveChanges} onClick={handleSubmit(handleUpdateCustomer)}/>
        </>
    }

    const formBody = () => {
        return <>
            <Row>
                <Col>
                    <MyTextInput label={messages.email}
                                 {...register("email")}
                                 errorText={getErrorMessage(errors.email?.message)}
                    />
                </Col>
                <Col>
                    <MyTextInput label={messages.address}
                                 {...register("address")}
                                 errorText={getErrorMessage(errors.address?.message)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <MyTextInput label={messages.displayName}
                                 {...register("displayName")}
                                 errorText={getErrorMessage(errors.displayName?.message)}
                    />
                </Col>
                <Col>
                    <MySelect labelId={""} label={messages.language}
                              {...register("language")}
                              errorText={getErrorMessage(errors.language?.message)}
                              className={""} selectId={""} data={
                        [
                            <option key={"PT"} value={"PT"}>PT</option>,
                            <option key={"EN"} value={"EN"}>EN</option>,
                        ]
                    }/>

                </Col>
            </Row>
            <Row>
                <p>Marcas</p>
                {selectedCustomer?.brandRegisters?.map(br => {
                    return <p>Número: {br.number} Sinal: {br.brandText} Instituto: {br.source}</p>
                }) ?? <></>}
            </Row>
        </>
    }


    return <>
        <MyModal handleClose={handleClose} show={showDetailsModal} size={"lg"}
                 title={`${messages.updateDetailsOf} ${selectedCustomer.name}`}
                 body={formBody()} footer={formFooter()}/>
    </>
}
export default CustomerDetailsModal