import {requests} from "./API";
import {Customer} from "../../models/Customer";
import {User} from "../../models/User";
import {IgnoredWord} from "../../models/IgnoredWord";
import {WatchingClass} from "../../models/WatchingClass";

const baseUrl = "/user"

export interface KeycloakUserGroup {
    username: string,
    userId: string,
    groupName: string,
    groupId: string,
}

const UserRequests = {
    login: (username: string, password: string) => requests.headers(`/login`, {username, password}),
    getUserDetails: () => requests.get<User>(`${baseUrl}`),
    getCustomerDetails: () => requests.get<Customer[]>(`${baseUrl}/customer`),
    getIgnoredWords: () => requests.get<IgnoredWord[]>(`${baseUrl}/ignoredWords`),
    associateUserToTeam: (userGroup: KeycloakUserGroup) => requests.put<boolean, KeycloakUserGroup>(`${baseUrl}/team`, userGroup),
    updateIgnoredWords: (ignoredWords: IgnoredWord[]) => requests.put<IgnoredWord[], IgnoredWord[]>(`${baseUrl}/ignoredWords`, ignoredWords),
    updateRelatedClasses: (relatedClasses: WatchingClass[]) => requests.put<WatchingClass[], WatchingClass[]>(`${baseUrl}/relatedClasses`, relatedClasses),
}

export default UserRequests