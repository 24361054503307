import {Container} from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import RenewalsByMonth from "./RenewalsByMonth";
import RenewalsByYear from "./RenewalsByYear";
import {useIntl} from "react-intl";

const Renewals = () => {

    const intl=useIntl()
    const messages={
        byMonths:intl.formatMessage({id:"renewals.label.byMonths"}),
        byYear:intl.formatMessage({id:"renewals.label.byYear"}),
    }
    const items = () => {
        return [
            {header: messages.byMonths, body: <RenewalsByMonth/>},
            {header: messages.byYear, body: <RenewalsByYear/>},
        ]
    }
    return <>
        <Container className={"mt-2"}>
            <MyAccordion items={items()}/>
        </Container>

    </>
}


export default Renewals