import {ChangeEvent, forwardRef,} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ChangeHandler} from "react-hook-form";


interface Props {
    label?: string,

    onChange: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void,
    onBlur?: ChangeHandler,
    value?: string | number,
    errorText?: string,
    name?: string,
    type?: string,
    inputClassName?: string,
    isOneLiner?: boolean,

}

const MyTextInput = forwardRef(({
                                    label,
                                    value,
                                    onChange,
                                    onBlur = undefined,
                                    errorText = "",
                                    inputClassName = undefined,
                                    name = "",
                                    type = "text",
                                    isOneLiner = false,
                                }: Props, ref?: any) => {

        const capitalizedLabel = label ? label?.split(" ").map((word) => {
            return word[0]?.toUpperCase() + word.substring(1);
        }).join(" "):undefined

        return <>
            {isOneLiner && <Row>
                {capitalizedLabel && <Col xs={1} className={"mt-2"}>
                    <Form.Label>{capitalizedLabel}</Form.Label>
                </Col>}
                <Col>
                    {!value && <Form.Control ref={ref} onBlur={onBlur} name={name} type={type} onChange={onChange}
                                             className={inputClassName}/>}
                    {value &&
                        <Form.Control ref={ref} value={value} onBlur={onBlur} name={name} type={type} onChange={onChange}
                                      className={inputClassName}/>}
                </Col>
            </Row>}
            {!isOneLiner && <>
                <Form.Label>{capitalizedLabel}</Form.Label>

                {!value && <Form.Control ref={ref} onBlur={onBlur} name={name} type={type} onChange={onChange}
                                         className={inputClassName}/>}
                {value && <Form.Control ref={ref} value={value} onBlur={onBlur} name={name} type={type} onChange={onChange}
                                        className={inputClassName}/>}
            </>}
            {errorText && <p className={"text-danger"}>{errorText}</p>}
        </>
    }
)
export default MyTextInput