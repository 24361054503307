import BrandRegisterRequests from "../../api/customer/BrandRegisterRequests";
import {BrandRegister} from "../../models/BrandRegister";
import {useAppDispatch} from "../hooks";
import {defaultRegister, useCustomerActions} from "../slices/CustomerSlice";
import {Customer} from "../../models/Customer";
import {useBrandRegistersAction} from "../slices/BrandRegisterSlice";
import {useIsLoadingActions} from "../slices/IsLoadingSlice";

const getRegisterDataFromAPI=(number:string, source:string, selectedCustomerId:number)=>{

    const {getRegisterDataFromAPI}=BrandRegisterRequests


    return async ()=>{
        return getRegisterDataFromAPI(number,source,selectedCustomerId)
    }
}


const deleteRegister=(brandRegisterId:number)=>{

    const {deleteRegister}=BrandRegisterRequests

    return async ()=>{
        return deleteRegister(brandRegisterId)
    }
}
const createRegister = (customerId: number, brandRegister: BrandRegister) => {
    const {createRegister} = BrandRegisterRequests

    return async () => {
        return createRegister(customerId, brandRegister)
    }

}

const updateRegister = (customerId: number, brandRegister: BrandRegister) => {
    const {updateRegister} = BrandRegisterRequests

    return async () => {
        return updateRegister(customerId, brandRegister)
    }
}

export const useBrandRegisterThunks = () => {

    const dispatch = useAppDispatch()

    const {selectedCustomer, updateCustomer, setSelectedRegister, setSelectedCustomer,selectedRegister} = useCustomerActions()
    const {registers, setRegisters} = useBrandRegistersAction()
    const {setIsLoadingModal}=useIsLoadingActions()


    return {
        createRegister: (customerId: number, brandRegister: BrandRegister) => {
            setIsLoadingModal(true)
            dispatch(createRegister(customerId, brandRegister)).then(registerCreatead => {
                const registers = [...selectedCustomer.brandRegisters, registerCreatead]
                const updatedCustomer = {...selectedCustomer, brandRegisters: registers}
                updateCustomer(updatedCustomer)
                setSelectedRegister(defaultRegister())
            }).finally(()=>{
                setIsLoadingModal(false)
            })
        },
        updateRegister: (customerId: number, brandRegister: BrandRegister) => {
            setIsLoadingModal(true)
            dispatch(updateRegister(customerId, brandRegister)).then(updatedRegister => {
                if (updatedRegister) {
                    const registerIndex = registers.findIndex(r => r.id === updatedRegister.id)
                    const updatedRegisters = [...registers]
                    updatedRegisters[registerIndex] = updatedRegister
                    setRegisters(updatedRegisters)
                    const updatedCustomer = {...selectedCustomer, brandRegisters: updatedRegisters} as Customer
                    updateCustomer(updatedCustomer)
                    setSelectedCustomer(updatedCustomer)
                }
            }).finally(()=>{
                setIsLoadingModal(false)
            })
        },
        deleteRegister: (brandRegisterId:number) => {
            setIsLoadingModal(true)
            dispatch(deleteRegister(brandRegisterId)).then(()=>{
                const updatedRegisters = registers.filter(brandRegister => brandRegister.id !== selectedRegister.id)
                setRegisters(updatedRegisters)
                const updatedCustomer = {...selectedCustomer, brandRegisters: updatedRegisters}
                updateCustomer(updatedCustomer)
                setSelectedRegister(defaultRegister())
                setSelectedCustomer(updatedCustomer)
            }).finally(()=>{
                setIsLoadingModal(false)
            })

        },
        getRegisterDataFromAPI:(number:string, source:string, selectedCustomerId:number,handleResult:(registerFetchedFromAPI:BrandRegister)=>void)=>{
            setIsLoadingModal(true)
            dispatch(getRegisterDataFromAPI(number,source,selectedCustomerId)).then(handleResult)
                .finally(()=>{
                    setIsLoadingModal(false)
                })
        }

    }

}