import {Col, Pagination, Row, Spinner} from "react-bootstrap";
import {useIntl} from "react-intl";
import {Page} from "../models/Page";
import {ReactElement} from "react";
import {useIsLoadingActions} from "../store/slices/IsLoadingSlice";


interface Props {

    table: ReactElement,
    searchButton: ReactElement,
    // isLoading: boolean,
    // setIsLoading: (isLoading: boolean) => void,
    page: Page<unknown>,
    currentPage: number,
    setCurrentPage: (page: number) => void,
    requestPage: (page: number) => Promise<unknown>|void

}

const  WrapperPageableTable= ({
                                  searchButton,
                                  table,
                                  // isLoading,
                                  // setIsLoading,
                                  page,
                                  currentPage,
                                  setCurrentPage,
                                  requestPage
                              }: Props,) => {

    const {setIsLoading,isLoading}=useIsLoadingActions()
    const intl = useIntl()
    const messages = {
        noResults: intl.formatMessage({id: "generics.label.noResults"}),
    }

    const createPageItem = (pageNumber: number) => {
        return <Pagination.Item key={pageNumber} active={currentPage + 1 === pageNumber}
                                onClick={(evt)=>handleItemSelection(evt)}>{pageNumber}</Pagination.Item>
    }


    const handleItemSelection = async (event: any) => {
        const page = parseInt(event.target.text) - 1
        if (!isNaN(page)) {
            setCurrentPage(page)
            setIsLoading(true)
            await requestPage(page)
            setIsLoading(false)
        }
    }

    const renderPaginationControl = (currentPage: number, totalPages: number) => {

        const pages = page?.totalPages
        const pageItems: JSX.Element[] = []
        const middlePages=11
        if (pages >= 20) {
            if (currentPage < middlePages) {
                for (let i = 1; i <= middlePages+1; i++) {
                    pageItems[i - 1] = createPageItem(i)
                }
                pageItems[middlePages+1] = <Pagination.Ellipsis key={-1}/>
                pageItems[middlePages+2] = createPageItem(pages)
            } else if (currentPage >= totalPages - middlePages) {
                pageItems[0] = createPageItem(1)
                pageItems[1] = <Pagination.Ellipsis key={-1}/>
                for (let i = 0; i < middlePages+1; i++) {
                    pageItems[middlePages+3 - i - 1] = createPageItem(pages - i)
                }
            } else {
                pageItems[0] = createPageItem(1)
                pageItems[1] = <Pagination.Ellipsis key={-1}/>
                const half=Math.floor(middlePages/2)
                for(let i=0;i<middlePages;i++){
                    pageItems[2+i]=createPageItem(currentPage+i-half+1)
                }
                pageItems[middlePages+3] = <Pagination.Ellipsis key={-2}/>
                pageItems[middlePages+4] = createPageItem(pages)
            }
            return pageItems
        }

        return pages ?
            <>{[...Array(pages + 1).keys()]
                .filter(it => it !== 0)
                .map(it => {
                    return <Pagination.Item key={it} active={currentPage + 1 === it}
                                            onClick={handleItemSelection}>{it}</Pagination.Item>

                })}
                <div className={"ms-2"}>Total: {page.totalElements}</div>
            </>
            : <>{messages.noResults}</>
    }

    return <>
        {isLoading && <Spinner animation={"border"}/>}
        <Row className={"justify-content-sm-between"}>
            <Col sm={"auto"}>
                {searchButton}
            </Col>
            <Col sm={"auto"}>
                <Pagination>
                    {
                        renderPaginationControl(currentPage, page.totalPages)
                    }
                </Pagination>
            </Col>
        </Row>
        {table}
    </>

}
export default WrapperPageableTable