import {LOCALES} from "./locales";

export interface Language {
    name: string
    code: string
}

export const languages: Language[] = [
    {name: "Português", code: LOCALES.PORTUGUESE},
    {name: "English", code: LOCALES.ENGLISH},
]