import {Customer} from "../../models/Customer";
import {AccordionItems} from "../../models/AccordionItems";
import {Form} from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import {BrandRegister} from "../../models/BrandRegister";
import {useIntl} from "react-intl";
import {SimpleRegister} from "../../models/SimpleRegister";

type CustomerCheckboxesProps = {
    customers: Customer[],
    selectedSimpleRegisters: SimpleRegister[],
    setSelectedSimpleRegisters: (simpleRegister: SimpleRegister[]) => void
    allSimpleRegisters: SimpleRegister[],
}

type CustomerDetailsProps = {
    registers: BrandRegister[],
    customer: Customer,
}

const CustomerCheckboxes = ({
                                customers,
                                selectedSimpleRegisters,
                                setSelectedSimpleRegisters,
                                allSimpleRegisters
                            }: CustomerCheckboxesProps) => {

    const intl = useIntl()
    const messages = {
        selectAll: intl.formatMessage({id: "similarities.label.selectAllRegisters"}),
    }


    const isAllCustomerRegisterSelected = (customer: Customer) => {
        const customerSelected = selectedSimpleRegisters.filter(reg => reg.customerName === customer.name)
        const allCustomer = allSimpleRegisters.filter(reg => reg.customerName === customer.name)

        return customerSelected.length === allCustomer.length
    }

    const toggleCustomerCheck = (customer: Customer) => {
        if (isAllCustomerRegisterSelected(customer)) {
            setSelectedSimpleRegisters(selectedSimpleRegisters.filter(reg => reg.customerName !== customer.name))
        } else {
            const registersWithoutCustomer = selectedSimpleRegisters.filter(reg => reg.customerName !== customer.name)
            const registersAddingCustomer = [...registersWithoutCustomer, ...allSimpleRegisters.filter(reg => reg.customerName === customer.name)]
            setSelectedSimpleRegisters(registersAddingCustomer)
        }

    }


    const CustomerDetails = ({registers, customer}: CustomerDetailsProps) => {
        return <>
            {
                registers.map(register => {
                    const simpleRegister = {
                        number: register?.number,
                        institute: register?.source,
                        customerName: customer.name
                    } as SimpleRegister
                    return simpleRegister ? <Form.Check
                        inline
                        key={register.number}
                        label={register.brandText}
                        type={"checkbox"}
                        name={"registers"}
                        checked={selectedSimpleRegisters.some(item => item.number === simpleRegister.number && item.institute === simpleRegister.institute)}
                        onChange={() => handleCheckboxChange(simpleRegister)}
                    /> : undefined
                })
            }
        </>
    }


    const handleCheckboxChange = (simpleRegister: SimpleRegister) => {
        if (selectedSimpleRegisters.some(item => item.number === simpleRegister.number && item.institute === simpleRegister.institute)) {
            setSelectedSimpleRegisters(selectedSimpleRegisters.filter((item) => item.number !== simpleRegister.number || item.institute !== simpleRegister.institute));
        } else {
            setSelectedSimpleRegisters([...selectedSimpleRegisters, simpleRegister]);
        }
    };


    const items = (customer: Customer): AccordionItems[] => {
        return [
            {
                header: <>{customer.name}</>,
                body: <>
                    <div>
                        <Form.Check inline label={messages.selectAll} checked={isAllCustomerRegisterSelected(customer)}
                                    onChange={() => toggleCustomerCheck(customer)}/>
                    </div>
                    <CustomerDetails registers={customer.brandRegisters} customer={customer}/>
                </>
            },
        ]
    }

    return <>{
        customers.map((customer) => {
            return <MyAccordion key={customer.name} items={items(customer)} defaultAsOpen={false}/>
        })
    }
    </>
}

export default CustomerCheckboxes