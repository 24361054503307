import {requests} from "./API";
import {WatchTask} from "../../models/WatchTask";


const baseUrl="/watchTask"
const WatchTaskRequests={
    closeWatch: (startDate:string, endDate:string, origin:string) => requests.post<WatchTask[],WatchTask[]>(`${baseUrl}/close/${startDate}/${endDate}/${origin}`,[]),
    openWatch: (startDate:string, endDate:string, origin:string) => requests.post<WatchTask[],WatchTask[]>(`${baseUrl}/open/${startDate}/${endDate}/${origin}`,[]),
    getWatchTaskList: (startDate:string, endDate:string, origin:string) => requests.get<WatchTask[]>(`${baseUrl}/${startDate}/${endDate}/${origin}`),
    getMonthWatchTasks:(date:string, origin:string)=>requests.get<WatchTask[]>(`${baseUrl}/task/month/${date}/${origin}`),
}

export default WatchTaskRequests