import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../hooks";

interface IsLoadingState {
    isLoading: boolean,
    isLoadingModal:boolean,
}

const isLoadingState: IsLoadingState = {
    isLoading: false,
    isLoadingModal:false,
}

const isLoadingSlice = createSlice({
    name: "isLoading",
    initialState: isLoadingState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setIsLoadingModal:(state, action:PayloadAction<boolean>)=>{
          state.isLoadingModal=action.payload
        },
    }
})


export default isLoadingSlice.reducer

export const useIsLoadingActions = () => {

    const dispatch = useAppDispatch()
    const {isLoading,isLoadingModal} = useAppSelector(state => state.isLoading)
    const {setIsLoading,setIsLoadingModal} = isLoadingSlice.actions

    return {
        isLoading,isLoadingModal,
        setIsLoading: (isLoading: boolean) => {
            dispatch(setIsLoading(isLoading))
        },
        setIsLoadingModal: (isLoading: boolean) => {
            dispatch(setIsLoadingModal(isLoading))
        },

    }
}