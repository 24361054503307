import React, {useEffect} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import MyDatePicker from "../../widgets/MyDatePicker";
import dayjs from "dayjs";
import {useKeycloak} from "@react-keycloak/web";
import MySelect from "../../widgets/MySelect";
import {mapInstitute} from "../../models/Institute";
import MyButton from "../../widgets/MyButton";
import {useIntl} from "react-intl";
import RegisterTable from "../../widgets/RegisterTable";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import {Page} from "../../models/Page";
import {BrandRegister} from "../../models/BrandRegister";
import {useIsLoadingActions} from "../../store/slices/IsLoadingSlice";
import {useBulletinsActions} from "../../store/slices/BulletinsSlice";
import {useBulletinsThunks} from "../../store/thunks/BulletinsThunks";

const Bulletins = () => {
    const {keycloak} = useKeycloak()

    const {isLoading, setIsLoading} = useIsLoadingActions()
    const {
        setRegisterPage,
        setCurrentPage,
        currentPage,
        registerPage,
        setPublicationDate,
        publicationDate,
        selectedInstitute, setSelectedInstitute
    } = useBulletinsActions()

    const {getAllRegistersByDateAndSource} = useBulletinsThunks()

    const intl = useIntl()
    const messages = {
        institute: intl.formatMessage({id: "model.institute"}),
        listPublications: intl.formatMessage({id: "bulletins.label.listPublications"}),
        noResults: intl.formatMessage({id: "generics.label.noResults"}),
    }

    useEffect(()=>{
        setRegisterPage({} as Page<BrandRegister[]>)
    },[])


    const handleRequestData = async () => {
        setRegisterPage({} as Page<BrandRegister[]>)
        setIsLoading(true)
        setCurrentPage(0)
        await requestPage(0)
        setIsLoading(false)
    }
    const requestPage = async (page: number) => {
        const result = await getAllRegistersByDateAndSource(publicationDate, selectedInstitute, page)
        if (result) {
            setRegisterPage(result)
        }
    }

    return <>
        <Container className={"mb-2 mt-5"}>
            <Row className={"mt-2"}>
                <Col>
                    <MyDatePicker taskWatchList={[]} onMonthChange={() => {
                    }} isIncident={false} date={dayjs(publicationDate)} onChangeDate={setPublicationDate}/>
                </Col>
                <Col>

                    <MySelect onChange={(evt) => setSelectedInstitute(evt.target.value)}
                              labelId={"institute-dropdown-label2"} label={messages.institute} value={selectedInstitute}
                              className={"mb-2"} selectId={"institute-dropdown"} data={
                        keycloak.tokenParsed?.institutes?.map((inst: string) => {
                            const institute = mapInstitute(inst)
                            if (institute) {
                                return <option key={institute.origin}
                                               value={institute.origin}>{institute.name}</option>
                            }
                        })
                    }/>

                </Col>
            </Row>

            <Row>
                <WrapperPageableTable table={<RegisterTable />}
                                      searchButton={<MyButton disabled={isLoading} label={messages.listPublications}
                                                              onClick={handleRequestData}/>}
                                      page={registerPage} currentPage={currentPage}
                                      setCurrentPage={setCurrentPage} requestPage={requestPage}
                />
            </Row>

            {isLoading && <Spinner animation={"border"}/>}

        </Container>
    </>
}

export default Bulletins;