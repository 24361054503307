import React, {ChangeEvent} from "react";
import RegisterForm from "./RegisterForm";
import {Customer} from "../../models/Customer";
import {defaultCustomer, defaultRegister, useCustomerActions} from "../../store/slices/CustomerSlice";
import {BrandRegister} from "../../models/BrandRegister";
import {useIntl} from "react-intl";
import {Colors} from "../../widgets/Colors";
import {useBrandRegisterThunks} from "../../store/thunks/BrandRegisterThunks";

const CustomerRegisterFormModal = () => {

    const intl = useIntl()
    const messages = {
        createNewRegister: intl.formatMessage({id: "customers.label.createNewRegister"}),
        createNewRegisterFor: intl.formatMessage({id: "customers.label.createNewRegisterFor"}),
        close: intl.formatMessage({id: "generics.label.close"}),
    }

    const {createRegister} = useBrandRegisterThunks()

    const {
        showRegisterModal,
        selectedCustomer,
        changeRegisterModal,
        setSelectedRegister,
        selectedRegister,
    } = useCustomerActions()

    const setShowCustomerRegistersModal = (show: boolean) => {
        changeRegisterModal(show)
    }
    const actualCustomer = (): Customer => {
        return selectedCustomer ?? defaultCustomer()
    }
    const handleClose = () => {
        setSelectedRegister(defaultRegister())
        setShowCustomerRegistersModal(false)
    };

    const handleCreateRegister = async (register: BrandRegister) => {
        register.id = undefined
        if (!selectedCustomer.id || !selectedCustomer.brandRegisters) {
            return
        }
        createRegister(selectedCustomer.id, register)
    }
    const handleRegister = (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
        const {name, value} = event.target
        const updatedRegister: BrandRegister = {...selectedRegister, [name]: value}
        setSelectedRegister(updatedRegister)
    }
    return (
        <RegisterForm handleRegister={handleRegister}
                      onSelectChange={() => {
                      }}
                      isUpdate={false} show={showRegisterModal}
                      size={"lg"}
                      title={`${messages.createNewRegisterFor} ${actualCustomer()?.name}`} actions={
            [
                {label: messages.close, action: handleClose, color: Colors.GRAY, isSubmit: false},
                {label: messages.createNewRegister, action: handleCreateRegister, color: Colors.BLUE, isSubmit: true}
            ]
        }/>
    )
}

export default CustomerRegisterFormModal;